import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClaimsData, selectClaimsData, selectLoadingStatus } from '../store/slice/VisualSlice';
import StartClaim from '../components/StartClaim';
import SyndicationAuth from '../components/SyndicationAuth';
import Loader from '../components/shared/Loader';
const userImage = require('../images/profile.png');
const backgroundImage = require('../images/Backround.jpg');

const Claims = () => {
    const dispatch = useDispatch();
    const claimsData = useSelector(selectClaimsData);
    const loading = useSelector(selectLoadingStatus);
    const [image, setImage] = useState(userImage);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        dispatch(fetchClaimsData());
        const profile_img = localStorage.getItem('profile_img');
        setImage(profile_img !== 'undefined' ? profile_img : userImage);
    }, [dispatch]);

    const handleClaimCreated = () => {
        dispatch(fetchClaimsData());
     
    };

    const handleNextPage = () => {
        if (currentPage * itemsPerPage < claimsData.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const displayedClaims = claimsData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const startRow = (currentPage - 1) * itemsPerPage + 1;
    const endRow = Math.min(currentPage * itemsPerPage, claimsData.length);

    return (
        <div>
            <div className="flex flex-wrap h-auto justify-between bg-cover bg-center shadow-lg rounded-lg"
                style={{ backgroundImage: `url(${backgroundImage})` }}
            >
                <div className="flex flex-col px-3 py-3">
                    <h1 className="poppins text-2xl font-semibold">Visual Claims</h1>
                    <p className="text-[16px] font-normal">Keep Track of video Progress and record...</p>
                </div>
                <div className="flex gap-4 p-4 flex-wrap">
                    <StartClaim onClaimCreated={handleClaimCreated} />
                    <SyndicationAuth />
                </div>
                <div className="w-full overflow-x-auto md:max-w-full md:overflow-hidden px-2">
                    <table className="w-full shadow-lg">
                        <thead className='text-white bg-blue-600'>
                            <tr>
                                <th>Username</th>
                                <th className="text-center">URL</th>
                                <th className="text-center">Date</th>
                                <th className="text-center">Assets</th>
                                <th className="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="5">
                                        <Loader />
                                    </td>
                                </tr>
                            ) : (
                                displayedClaims.map((claim) => (
                                    <tr key={claim._id}>
                                        <td>
                                            <div className="flex gap-2 items-center">
                                                <img src={image} className="w-10 h-10 rounded-full" alt="User" />
                                                <div>
                                                    <p className="font-semibold">{claim.creatorName}</p>
                                                    <p className="text-gray-500">{claim.clientId}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <a href={claim.videoUrl} target="_blank" rel="noopener noreferrer">
                                                VideoURL
                                            </a>
                                        </td>
                                        <td className="text-center">{new Date(claim.date).toLocaleDateString()}</td>
                                        <td className="text-center">{claim.assetsName}</td>
                                        <td className="text-center">
                                            <button
                                                className={`h-8 font-semibold w-24 rounded ${
                                                    claim.status === 'Rejected' ? 'bg-red-500' : 'bg-blue-500'
                                                } text-white p-1`}
                                            >
                                                {claim.status}
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    {!loading && (
                        <div className="mt-2">
                            <p className="mt-6 text-red-500 text-gray-600">
                                Showing {startRow}-{endRow} of {claimsData.length} rows selected
                            </p>
                        </div>
                    )}
                </div>
                <div className="flex justify-between w-full p-4">
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="bg-blue-500 text-white p-2 rounded disabled:bg-gray-400"
                    >
                        Previous
                    </button>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage * itemsPerPage >= claimsData.length}
                        className="bg-blue-500 text-white p-2 rounded disabled:bg-gray-400"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Claims;
