import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Recent from '../components/Recent';
import Status from '../components/Status';
import TotalAssets from '../components/charts/TotalAssets';
import TotalRevenue from '../components/charts/TotalRevenue';
import Loader from '../components/shared/Loader'; 
import { fetchMonthWiseViews, fetchTotalRevenueByMonth, selectMonthWiseViews, selectMonthWiseViewsStatus, selectTotalRevenueByMonth, selectTotalRevenueByMonthStatus } from '../store/slice/dashSlice'; 

export default function Dashboard() {
    const dispatch = useDispatch();
    const monthWiseViews = useSelector(selectMonthWiseViews);
    const monthWiseViewsStatus = useSelector(selectMonthWiseViewsStatus);
    const totalRevenueByMonth = useSelector(selectTotalRevenueByMonth);
    const totalRevenueByMonthStatus = useSelector(selectTotalRevenueByMonthStatus);

    useEffect(() => {
        dispatch(fetchMonthWiseViews());
        dispatch(fetchTotalRevenueByMonth());
    }, [dispatch]);

    if (monthWiseViewsStatus === 'loading' || totalRevenueByMonthStatus === 'loading') {
        return <Loader />;
    }

    const dates = monthWiseViews.map(view => ({
        x: new Date(`2024-${view.month}-01`).getTime(),
        y: view.totalViews
    }));

    const revenueDates = totalRevenueByMonth.map(revenue => ({
        x: new Date(`2024-${revenue.month}-01`).getTime(),
        y: revenue.totalRevenue
    }));

    return (
        <div className="flex flex-col gap-2">
            <Status />
            <div className="flex flex-col lg:flex-row gap-2 w-full">
                <div className="w-full lg:w-1/2">
                    <TotalAssets dates={dates} />
                </div>
                <div className="w-full lg:w-1/2">
                    <TotalRevenue dates={revenueDates} />
                </div>
            </div>
            <div className="flex flex-row gap-2 w-full">
                <Recent />
            </div>
        </div>
    );
}
