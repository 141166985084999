import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const TotalRevenue = ({ dates }) => {
  const [series, setSeries] = useState([
    {
      name: 'Total Views',
      data: dates,
    },
  ]);

  const [options] = useState({
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom',
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: 'Total Views',
      align: 'left',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
      title: {
        text: 'Views',
      },
    },
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
  });

  return (
    <div>
      <div id="chart" className='bg-white shadow-xl transition ease-in-out  hover:-translate-y-6 hover:scale-90'
      style={{ 
      boxShadow: '4px 4px 4px rgba(56, 189, 248, 0.4)',
     }}
      >
        <ReactApexChart options={options} series={series} type="area" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default TotalRevenue;
