import React, { useState, useEffect } from 'react';
import Modal from '../components/ui/Modal';
import { useDispatch } from 'react-redux';
import { createClaimRelease } from '../store/slice/ReleaseSlice';
import Swal from 'sweetalert2'; 
const backgroundImage = require('../images/modalbg.png');

export default function ReleaseClaim({ onClaimReleased }) {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        creatorName: '',
        clientId: '',
        date: new Date().toISOString().split('T')[0],
        contentIdBy: '',
        videoUrl: ''
    });
    const [errors, setErrors] = useState({}); 

    const dispatch = useDispatch();

    useEffect(() => {
        const storedClientId = localStorage.getItem('clientID');
        const fullName = localStorage.getItem('fullName');
        if (storedClientId) {
            setFormData((prevData) => ({
                ...prevData,
                clientId: storedClientId,
                creatorName: fullName
            }));
        }
    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.creatorName) newErrors.creatorName = 'Creator Name is required';
        if (!formData.clientId) newErrors.clientId = 'Client ID is required';
        if (!formData.contentIdBy) newErrors.contentIdBy = 'Content By ID is required';
        if (!formData.videoUrl) newErrors.videoUrl = 'Video URL is required';
        return newErrors;
    };

    const handleSubmit = async () => {
        const validationErrors = validateForm();
        const storedClientId = localStorage.getItem('clientID');
        const fullName = localStorage.getItem('fullName');
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            await dispatch(createClaimRelease(formData)).unwrap();

            Swal.fire({
                icon: 'success',
                title: 'Claim Release Created Successfully!',
                text: 'Your request has been submitted.',
            });

            
            setFormData({
                creatorName: fullName || '',
                clientId: storedClientId || '',
                date: new Date().toISOString().split('T')[0], 
                contentIdBy: '',
                videoUrl: ''
            });

        
            setErrors({});

            setOpen(false);

            if (onClaimReleased) {
                onClaimReleased();
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error creating the claim release. Please try again.',
            });
        }
    };

    return (
        <main className="App">
            <button
                className="transition ease-in-out hover:-translate-y-1 hover:scale-110 bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 p-2 text-white rounded-lg"
                onClick={() => setOpen(true)}
            >
                Claim Release Request
            </button>

            <Modal open={open} onClose={() => setOpen(false)}>
                <div
                    className="h-[28rem] bg-black border-green-500"
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                >
                    <div className="flex justify-between items-center">
                        <div className="p-2 mt-2 w-full">
                            <h1 className="text-white font-semibold text-xl">Video Claim Release Request</h1>
                            <p className="text-neutral-200 text-sm">Submit Video Url For Releasing Claim</p>
                        </div>
                    </div>
                    <div className="modal-body w-full px-10 py-2 flex flex-col gap-2">
                        <div className="grid grid-cols-2 gap-2 text-white">
                            <label htmlFor="creatorName" className="flex flex-col gap-2 text-white">
                                Creator Name (Auto)
                                <input
                                    type="text"
                                    id="creatorName"
                                    name="creatorName"
                                    value={formData.creatorName}
                                    readOnly
                                    className={`border ${errors.creatorName ? 'border-red-500' : 'border-white'} text-gray-400 rounded-md p-1 bg-black`}
                                />
                                {errors.creatorName && <span className="text-red-500 text-sm">{errors.creatorName}</span>}
                            </label>
                            <label htmlFor="clientId" className="flex flex-col gap-2">
                                Client ID
                                <input
                                    type="text"
                                    id="clientId"
                                    name="clientId"
                                    value={formData.clientId}
                                    readOnly
                                    className={`border ${errors.clientId ? 'border-red-500' : 'border-white'} text-gray-400 rounded-md p-1 bg-black`}
                                />
                                {errors.clientId && <span className="text-red-500 text-sm">{errors.clientId}</span>}
                            </label>

                            <label htmlFor="date" className="flex flex-col gap-2">
                                Date
                                <input
                                    type="text"
                                    id="date"
                                    name="date"
                                    value={formData.date}
                                    readOnly
                                    className="border border-white text-gray-400 rounded-md p-1 bg-black"
                                />
                            </label>

                            <label htmlFor="contentIdBy" className="flex flex-col gap-2 text-nowrap">
                                Content By ID
                                <input
                                    type="text"
                                    id="contentIdBy"
                                    name="contentIdBy"
                                    value={formData.contentIdBy}
                                    onChange={handleInputChange}
                                    className={`border ${errors.contentIdBy ? 'border-red-500' : 'border-white'} rounded-md p-1 bg-black`}
                                />
                                {errors.contentIdBy && <span className="text-red-500 text-sm">{errors.contentIdBy}</span>}
                            </label>
                            <label htmlFor="videoUrl" className="flex flex-col gap-2 text-white">
                                Video URL
                                <input
                                    type="text"
                                    id="videoUrl"
                                    name="videoUrl"
                                    value={formData.videoUrl}
                                    onChange={handleInputChange}
                                    className={`border ${errors.videoUrl ? 'border-red-500' : 'border-white'} rounded-md p-1 bg-black`}
                                />
                                {errors.videoUrl && <span className="text-red-500 text-sm">{errors.videoUrl}</span>}
                            </label>
                        </div>
                    </div>
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn m-8 btn-primary w-[80%] bg-gradient-to-r from-sky-400 to-sky-800"
                    >
                        Submit
                    </button>
                </div>
            </Modal>
        </main>
    );
}
