import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    success: false,
    error: null,
    syndicateData: null
};


export const createSyndicateAuthorization = createAsyncThunk(
    'syndicate/createSyndicateAuthorization',
    async (formData, thunkAPI) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found. Please login again.');
            }

            const response = await fetch(`${process.env.REACT_APP_API_KEY}/v1/api/syndicationAuthorization/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(formData)  
            });

            if (!response.ok) {
                throw new Error('Failed to create syndicate authorization');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


export const updateSyndicateInstructionPic = createAsyncThunk(
    'syndicate/updateSyndicateInstructionPic',
    async ({ id, formData }, thunkAPI) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found. Please login again.');
            }

            const response = await fetch(`${process.env.REACT_APP_API_KEY}/v1/api/syndicationAuthorization/${id}/updateInstructionPic`, {
                method: 'PUT', 
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to update instruction pic');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const syndicateSlice = createSlice({
    name: 'syndicate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createSyndicateAuthorization.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            })
            .addCase(createSyndicateAuthorization.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.error = null;
                state.syndicateData = action.payload.data;
            })
            .addCase(createSyndicateAuthorization.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload;
            })
            .addCase(updateSyndicateInstructionPic.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            })
            .addCase(updateSyndicateInstructionPic.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.error = null;
                if (state.syndicateData) {
                    state.syndicateData.instructionPic = action.payload.data.instructionPic;
                }
            })
            .addCase(updateSyndicateInstructionPic.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload;
            });
    }
});

export const syndicateActions = syndicateSlice.actions;
export default syndicateSlice.reducer;
