import React, { useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { registerUser } from '../../store/slice/AuthSlice'
import Selection from '../../components/ui/Selection'
import toast from 'react-hot-toast'
import CustomAlert from '../../components/ui/CustomAlert'
const TermAccept = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const sigCanvas = useRef(null)
    const [legalName, setLegalName] = useState('')
    const [labelName, setLabelName] = useState('')
    const [govIdFile, setGovIdFile] = useState(null)
    const [governmentId, setGovernmentId] = useState('')
    const [govAddrFile, setGovAddrFile] = useState(null)
    const [governmentAddress, setGovernmentAddress] = useState('')
    const [digitalSignature, setDigitalSignature] = useState(null)
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({ show: false, icon: '', title: '', message: '' })

    const formData = useSelector((state) => state.auth.formData)
    const regionData = useSelector((state) => state.auth.regionData)

    const govIdOptions = ['Aadhar Card', 'Voter Card', 'Driving License', 'Pan', 'Citizen Identity', 'Others']
    const govAddressOptions = ['Aadhar Card', 'Voter Card', 'Driving License', 'Citizen Identity', 'Others']

    const handleFileChange = (e, setter) => {
        setter(e.target.files[0])
    }

    const clearSignature = () => {
        sigCanvas.current.clear()
    }

    const saveSignature = () => {
        const dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
        setDigitalSignature(dataUrl)
        toast.success('Signature Saved Successfully')
    }

    const dataURLtoBlob = (dataurl) => {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new Blob([u8arr], { type: mime })
    }

    const validateForm = () => {
        const newErrors = {}

        if (!legalName) newErrors.legalName = 'Legal name is required'
        if (!labelName) newErrors.labelName = 'Label name is required'
        if (!govIdFile) newErrors.govIdFile = 'Government ID attachment is required'
        if (!governmentId) newErrors.governmentId = 'Government ID name is required'
        if (!govAddrFile) newErrors.govAddrFile = 'Government address attachment is required'
        if (!governmentAddress) newErrors.governmentAddress = 'Government address name is required'
        if (!digitalSignature) newErrors.digitalSignature = 'Digital signature is required'
        if (!termsAccepted) newErrors.termsAccepted = 'You must accept the terms and conditions'

        setErrors(newErrors)

        return Object.keys(newErrors).length === 0
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!validateForm()) {
            toast.error('Please fill all required fields.')
            return
        }

        setLoading(true)

        const completeData = {
            ...formData,
            ...regionData,
            legalName,
            labelName,
            termsAccepted,
            governmentId,
            governmentAddress
        }

        const formDataPayload = new FormData()
        for (const [key, value] of Object.entries(completeData)) {
            formDataPayload.append(key, value)
        }
        formDataPayload.append('governmentId', govIdFile)
        formDataPayload.append('governmentAddress', govAddrFile)

        const signatureBlob = dataURLtoBlob(digitalSignature)
        formDataPayload.append('digitalSignature', signatureBlob, 'signature.png')

        for (const [key, value] of formDataPayload.entries()) {
            console.log(key, value)
        }

        try {
            const response = await dispatch(registerUser(formDataPayload)).unwrap()
            if (response.status === true) {
                setAlert({
                    show: true,
                    icon: 'success',
                    title: 'Thank You',
                    message: 'Registration successful. Please check your email for verification.'
                })
                setTimeout(() => {
                    navigate('/login')
                }, 3000)
            } else {
                setAlert({
                    show: true,
                    icon: 'error',
                    title: 'Error',
                    message: 'Registration failed. Please try again.'
                })
            }
        } catch (error) {
            console.error('Submission failed:', error)
            setAlert({ show: true, icon: 'error', title: 'Error', message: 'Registration failed. Please try again.' })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="flex items-center justify-center bg-black w-full h-full py-16">
            <div className="flex w-[90vw] lg:w-[900px] shadow-md">
                <div className="flex flex-col gap-4 items-center bg-white justify-center h-[100%] w-[60%] p-4">
                    <div className="flex flex-col items-center justify-center">
                        <h2 className="font-bold text-xl">Selected Region: {regionData.region}</h2>
                        <p className="text-center">
                            Choosing the right region is a pivotal decision that can significantly impact various
                            aspects.
                        </p>
                    </div>

                    <form className="flex flex-col gap-4 p-4" onSubmit={handleSubmit}>
                        <div className="flex flex-col">
                            <label>Legal Name <span className="text-rose-600 font-bold">*</span></label>
                            <input
                                type="text"
                                className="appearance-none border border-black rounded w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none"
                                value={legalName}
                                onChange={(e) => setLegalName(e.target.value)}
                                placeholder="Your legal name"
                            />
                        </div>
                        <div className="border border-black p-2 rounded-md">
                            <span className="text-sm font-bold">Digital Distribution Terms of Service</span>
                            <br />
                            <br />
                            <span className="text-sm">
                                <h4 className="font-bold">A. Digital Distribution Terms of Service:</h4>
                                1. <span className="font-semibold">Grant of Rights:</span> Brandroute Media is granted
                                exclusive global rights to act as the licensor and digital distributor, manage digital
                                rights on platforms like YouTube, and handle revenue distribution.
                                <br />
                                2. <span className="font-semibold">Client Responsibilities:</span> The Client must
                                secure all necessary rights and provide complete content details to Brandroute Media.
                                <br />
                                3. <span className="font-semibold">Company Responsibilities:</span> Brandroute Media
                                will promote and monetize the Client's content, retaining 20% of gross revenue for its
                                services.
                                <br />
                                <br />
                                <h4 className="font-bold">B. Fingerprinting Terms and Service:</h4>
                                <span className="font-semibold">Digital Rights Management:</span> Brandroute Media will
                                handle digital rights, content ID, and monetization.
                                <br />
                                <span className="font-semibold">Content Ownership:</span> The Client must own 100% of
                                the content to be eligible for Content ID.
                                <br />
                                <span className="font-semibold">Prohibited Actions:</span> Manipulative practices and
                                unauthorized remixes or covers are prohibited.
                                <br />
                                <br />
                                <h4 className="font-bold">C. MCN Terms Of Service:</h4>
                                1. <span className="font-semibold">MCN Services:</span> 70% of gross compensation is
                                paid to the Client; 30% is retained by Brandroute Media.
                                <br />
                                2. <span className="font-semibold">Channel Access:</span> Owner access cannot be removed
                                from channels created by the MCN.
                                <br />
                                3. <span className="font-semibold">Linked Channel:</span> Manager access must be granted
                                to Brandroute Media's official emails.
                                <br />
                                4. <span className="font-semibold">Minimum Period:</span> Channels must remain linked
                                for a minimum of two years.
                                <br />
                                5. <span className="font-semibold">Content ID & Copyright:</span> No Content ID for the
                                channel; copyright strikes must be resolved within 14 business days.
                                <br />
                                <br />
                                <h4 className="font-bold">D. Payment Schedule:</h4>
                                1. <span className="font-semibold">Payments:</span> Made by the 20th of each month;
                                Client must update payment details promptly to avoid interruptions.
                                <br />
                                2. <span className="font-semibold">Payments:</span> Brandroute Media will maintain
                                transaction records and notify the Client of non-payments, offering a 30-day cure
                                period.
                                <br />
                                <br />
                                <h4 className="font-bold">E. Ownership and Intellectual Property Rights:</h4>
                                1. <span className="font-semibold">Confidentiality:</span> Both parties agree to
                                confidentiality regarding the Agreement.
                                <br />
                                <br />
                                <h4 className="font-bold">F. Content Submission Restrictions:</h4>
                                1. <span className="font-semibold">Prohibited Actions:</span> Manipulative practices and
                                unauthorized remixes or covers are prohibited. The Client is responsible for all
                                royalties.
                                <br />
                                <br />
                                <h4 className="font-bold">G.General Terms:</h4>
                                1. <span className="font-semibold">No Third-Party Rights:</span> This agreement does not
                                create any third-party rights.
                                <br />
                                2. <span className="font-semibold">Relationship:</span> The agreement does not establish
                                a partnership, joint venture, or employment relationship.
                                <br />
                                3. <span className="font-semibold">Legal Jurisdiction :</span> Disputes will be resolved
                                in Kolkata, West Bengal, India.
                                <br />
                                4. <span className="font-semibold">Entire Agreement :</span> This document constitutes
                                the entire agreement between the parties.
                                <br />
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <label>Label Name <span className="text-rose-600 font-bold">*</span></label>
                            <input
                                type="text"
                                value={labelName}
                                onChange={(e) => setLabelName(e.target.value)}
                                className="appearance-none border border-black rounded w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none"
                                placeholder="Your label name"
                            />
                        </div>

                        <div>
                            <label>Select Government ID Proof <span className="text-rose-600 font-bold">*</span></label>
                        </div>

                        <div className="  flex gap-2 flex-col lg:flex-row justify-center items-center">
                            <Selection
                                label="Govt ID Name"
                                options={govIdOptions}
                                selectedValue={governmentId}
                                onSelect={setGovernmentId}
                            />
                            <input type="file" onChange={(e) => handleFileChange(e, setGovIdFile)} />
                        </div>

                        <div>
                            <label>Select Government Address Proof <span className="text-rose-600 font-bold">*</span></label>
                        </div>

                        <div className=" flex gap-2 flex-col lg:flex-row justify-center items-center">
                            <Selection
                                label="Govt ID Address"
                                options={govAddressOptions}
                                selectedValue={governmentAddress}
                                onSelect={setGovernmentAddress}
                            />
                            <input type="file" onChange={(e) => handleFileChange(e, setGovAddrFile)} />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="digitalsignature">Digital Signature <span className="text-rose-600 font-bold">*</span></label>
                            <SignatureCanvas
                                ref={sigCanvas}
                                penColor="black"
                                canvasProps={{ width: 300, height: 150, className: 'border border-black' }}
                            />
                            <div className="flex gap-2">
                                <button
                                    type="button"
                                    onClick={clearSignature}
                                    className="border border-black px-2 py-1"
                                >
                                    Clear
                                </button>
                                <button type="button" onClick={saveSignature} className="border border-black px-2 py-1">
                                    Save Signature
                                </button>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={termsAccepted}
                                    onChange={(e) => setTermsAccepted(e.target.checked)}
                                />
                                <span className="ml-2">I accept the terms and conditions</span>
                            </label>
                        </div>

                        <div className="flex items-center justify-center">
                            <button
                                type="submit"
                                className={`bg-black text-white font-bold py-2 px-4 rounded ${
                                    loading ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                                disabled={loading}
                            >
                                {loading ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </form>
                </div>
                <div
                    className="w-[40%]"
                    style={{
                        backgroundImage: 'url(assets/bgform.png)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                ></div>
            </div>
            <CustomAlert
                show={alert.show}
                icon={alert.icon}
                title={alert.title}
                message={alert.message}
                onClose={() => setAlert({ show: false, icon: '', title: '', message: '' })}
            />
        </div>
    )
}

export default TermAccept
