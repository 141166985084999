import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { getCmsEntriesUser } from '../store/slice/mcnSlice'; 
import Loader from '../components/shared/Loader';
const userImage = require('../images/profile.png')
const backgroundImage = require('../images/Backround.jpg')

const ConnectList = () => {
    const dispatch = useDispatch();
    const [image, setImage] = useState(userImage)
    const [name,SetName]=useState()
    const [ID,SetID]=useState()
    const { cmsEntries, loading, error } = useSelector((state) => state.mcn);

    useEffect(() => {
        dispatch(getCmsEntriesUser());
        const profile_img = localStorage.getItem('profile_img')
        setImage(profile_img !='undefined' ? profile_img : userImage)
        const storedClientId = localStorage.getItem('clientID')
        const fullName = localStorage.getItem('fullName')
        SetName(fullName)
        SetID(storedClientId)
    }, [dispatch]);

    if (loading === true) {
        return <Loader />
    }

    if (!cmsEntries) {
        return <div>No data available</div>
    }

    return (
        <div className="flex flex-col gap-4 bg-cover bg-center justify-between  shadow-lg rounded-lg"
        style={{ backgroundImage: `url(${backgroundImage})`}}
        >
             <div className="flex flex-col px-3 py-3 ">
                    <h1 className="poppins text-2xl  font-semibold">Multi-Channel Network</h1>
                    <p className="text-[16px] font-normal">Keep track of mcn channels record...</p>
                </div>
           <div className='className="mt-2 w-full p-4'>
            <Table className="bg-white">
                <Thead className='text-white bg-blue-600'>
                    <Tr>
                    <Th className="font-bold"> Name</Th>
                        <Th className="font-bold">CMS Name</Th>
                        <Th className="font-bold">Channel Name</Th>
                        <Th className="font-bold">URL</Th>
                        <Th className="font-bold">Sharing</Th>
                        <Th className="font-bold">Date</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {cmsEntries.map((item, index) => (
                        <Tr key={index}>
                             <Td>
                                        <div className="flex gap-2 items-center">
                                            <img src={image} className="w-10 h-10 rounded-full" alt="User" />
                                            <div>
                                                <p className="font-semibold">{name}</p>
                                                <p className="text-gray-500">{ID}</p>
                                            </div>
                                        </div>
                                    </Td>
                            <Td>{item.cmsName}</Td>
                            <Td>{item.channelName}</Td>
                            <Td >
                                <a href={item.channelUrl} target="_blank" rel="noopener noreferrer">
                                    Channel URL
                                </a>
                            </Td>
                            <Td>{item.sharing}</Td>
                            <Td>{new Date(item.createdAt).toLocaleDateString()}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            </div>
        </div>
    );
};

export default ConnectList;
