import React from 'react';

const DistributionConnect = () => {
  return (
    <div className="musicd text-white h-[100%] flex justify-center items-center">
      <a href="http://distro.zojakworldwide.com" target="_blank" rel="noopener noreferrer">
        <button className='bg-gradient-to-r from-white to-blue-500 hover:from-pink-500 hover:to-yellow-500 text-black px-4 rounded-lg py-2 font-semibold transition ease-in-out  hover:-translate-y-1 hover:scale-110'>
          Connect
        </button>
      </a>
    </div>
  );
};

export default DistributionConnect;
