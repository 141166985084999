import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchClaimsData, selectClaimsData, selectLoadingStatus } from '../store/slice/VisualSlice'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
const userImage = require('../images/profile.png')

const Recent = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const claimsData = useSelector(selectClaimsData)
    const loading = useSelector(selectLoadingStatus)
    const [displayedClaims, setDisplayedClaims] = useState([])
    const [image, setImage] = useState(userImage)

    useEffect(() => {
        dispatch(fetchClaimsData())
        const profile_img = localStorage.getItem('profile_img')
        setImage(profile_img !='undefined' ? profile_img : userImage)
    }, [dispatch])

    useEffect(() => {
        setDisplayedClaims(claimsData.slice(0, 5))
    }, [claimsData])

    

    const handleShowAll = () => {
        navigate('/app/claims')
    }

    return (
        <div className='w-full'>
            <Table className="bg-white">
                <Tbody>
                    {displayedClaims.map((claim) => (
                        <Tr key={claim._id}>
                            <Td>
                                <div className="flex gap-2 items-center">
                                    <img src={image}
                                        className='w-10 h-10 rounded-full'
                                        alt="User"
                                    />
                                    <div>
                                        <p className="font-semibold">{claim.creatorName}</p>
                                        <p className='text-gray-500'>{claim.clientId}</p>
                                    </div>
                                </div>
                            </Td>
                            <Td>
                                <a href={claim.videoUrl} target="_blank" rel="noopener noreferrer">
                                    Video URL
                                </a>
                            </Td>
                            <Td>{new Date(claim.date).toLocaleDateString()}</Td>
                            <Td className='text-center'>{claim.assetsName}</Td>
                            <Td className='text-center'>
                                <button
                                    className={`h-8 font-semibold w-24 rounded ${
                                        claim.status === 'Rejected' ? 'bg-red-500' : 'bg-blue-500'
                                    } text-white p-1`}
                                >
                                    {claim.status}
                                </button>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <div className="flex justify-center mt-4">
                <button onClick={handleShowAll} className="h-10 font-semibold w-32 rounded bg-blue-500 text-white">
                    Show All
                </button>
            </div>
        </div>
    )
}

export default Recent
