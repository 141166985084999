import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    dashboardStats: {
        data: null,
        status: 'idle',
        error: null,
    },
    monthWiseViews: {
        data: [],
        status: 'idle',
        error: null,
    },
    totalRevenueByMonth: {
        data: [],
        status: 'idle',
        error: null,
    }
};

export const fetchDashboardStats = createAsyncThunk('dashboard/fetchDashboardStats', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_KEY}/v1/api/user/dashboard/stats`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
    return response.data.data;
});

export const fetchMonthWiseViews = createAsyncThunk('dashboard/fetchMonthWiseViews', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_KEY}/v1/api/views/monthWise`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
    return response.data.data;
});

export const fetchTotalRevenueByMonth = createAsyncThunk('dashboard/fetchTotalRevenueByMonth', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_KEY}/v1/api/revenue/totalByMonth`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
    return response.data.data;
});

const dashSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // fetchDashboardStats
            .addCase(fetchDashboardStats.pending, (state) => {
                state.dashboardStats.status = 'loading';
            })
            .addCase(fetchDashboardStats.fulfilled, (state, action) => {
                state.dashboardStats.status = 'succeeded';
                state.dashboardStats.data = action.payload;
            })
            .addCase(fetchDashboardStats.rejected, (state, action) => {
                state.dashboardStats.status = 'failed';
                state.dashboardStats.error = action.error.message;
            })
            // fetchMonthWiseViews
            .addCase(fetchMonthWiseViews.pending, (state) => {
                state.monthWiseViews.status = 'loading';
            })
            .addCase(fetchMonthWiseViews.fulfilled, (state, action) => {
                state.monthWiseViews.status = 'succeeded';
                state.monthWiseViews.data = action.payload;
            })
            .addCase(fetchMonthWiseViews.rejected, (state, action) => {
                state.monthWiseViews.status = 'failed';
                state.monthWiseViews.error = action.error.message;
            })
            // fetchTotalRevenueByMonth
            .addCase(fetchTotalRevenueByMonth.pending, (state) => {
                state.totalRevenueByMonth.status = 'loading';
            })
            .addCase(fetchTotalRevenueByMonth.fulfilled, (state, action) => {
                state.totalRevenueByMonth.status = 'succeeded';
                state.totalRevenueByMonth.data = action.payload;
            })
            .addCase(fetchTotalRevenueByMonth.rejected, (state, action) => {
                state.totalRevenueByMonth.status = 'failed';
                state.totalRevenueByMonth.error = action.error.message;
            });
    }
});

export const selectDashboardStats = (state) => state.dashboard.dashboardStats.data;
export const selectLoadingStatus = (state) => state.dashboard.dashboardStats.status;
export const selectError = (state) => state.dashboard.dashboardStats.error;
export const selectMonthWiseViews = (state) => state.dashboard.monthWiseViews.data;
export const selectMonthWiseViewsStatus = (state) => state.dashboard.monthWiseViews.status;
export const selectMonthWiseViewsError = (state) => state.dashboard.monthWiseViews.error;
export const selectTotalRevenueByMonth = (state) => state.dashboard.totalRevenueByMonth.data;
export const selectTotalRevenueByMonthStatus = (state) => state.dashboard.totalRevenueByMonth.status;
export const selectTotalRevenueByMonthError = (state) => state.dashboard.totalRevenueByMonth.error;

export default dashSlice.reducer;
