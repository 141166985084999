import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

//TO SEND THE OTP HERE 
export const sendOTP = createAsyncThunk('forgot/sendOTP', async (email) => {
    const response = await axios.post(`${process.env.REACT_APP_API_KEY}/v1/api/sendOTP`, { email },
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        }
    );
    return response.data;
});

//TO VERIFY THE OTP HERE 
export const verifyOTP = createAsyncThunk('forgot/verifyOTP', async ({ email, otp, newPassword }) => {
    const response = await axios.post(`${process.env.REACT_APP_API_KEY}/v1/api/verifyOTP`, { email, otp, newPassword },
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        }
    );
    return response.data;
});

const forgotSlice = createSlice({
    name: 'forgot',
    initialState: {
        loading: false,
        error: null,
        otpSent: false,
        verified: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(sendOTP.pending, (state) => {
                state.loading = true;
            })
            .addCase(sendOTP.fulfilled, (state, action) => {
                state.loading = false;
                state.otpSent = true;
            })
            .addCase(sendOTP.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(verifyOTP.pending, (state) => {
                state.loading = true;
            })
            .addCase(verifyOTP.fulfilled, (state, action) => {
                state.loading = false;
                state.verified = true;
            })
            .addCase(verifyOTP.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export default forgotSlice.reducer;
