import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { loginUser } from '../store/slice/AuthSlice';
import CustomAlert from '../components/ui/CustomAlert';
import OTPSend from '../components/OTPSend';
import { useAuth } from '../context/AuthContext';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState({ show: false, icon: '', title: '', message: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(loginUser({ email, password })).unwrap();
      setAlert({
        show: true,
        icon: 'success',
        title: 'Thank You',
        message: 'You Have Logged in Successfully!',
      });
      login();
      setTimeout(() => {
        setAlert({ ...alert, show: false });
        navigate('/app');
      }, 2500); 
    } catch (error) {
      setAlert({
        show: true,
        icon: 'error',
        title: 'Error',
        message: 'Login Failed!  Please check credentials or contact us.',
      });
      setTimeout(() => {
        setAlert({ ...alert, show: false });
      }, 2500); 
    }
  };

  return (
    <div
      className="flex items-center justify-center h-screen"
      style={{
        backgroundImage: `url('assets/bgform.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="bg-white p-6 rounded shadow-md w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-4 text-center">Login in Your Account</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label htmlFor="email" className="block text-gray-700">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              className="w-full px-3 py-2 border border-gray-300 rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={email}
              placeholder='Enter your email address'
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-10">
            <label htmlFor="password" className="block text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-3 py-2 border border-gray-300 rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={password}
              placeholder='Enter your password'
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <p className='text-[14px] mb-6 text-center text-gray-600'>
            By continuing, you agree to the updated Terms of Condition, Terms of Service, and Privacy Policy.
          </p>
          <button
            type="submit"
            className="w-full bg-gradient-to-r from-indigo-700 to-zinc-800 text-white py-2 rounded hover:bg-blue-600 transition duration-200"
          >
            Submit
          </button>

          <div className='flex justify-between items-center p-2 mt-4'>
           <div>
           <OTPSend />
           </div>
            <Link to='/'>
              <p className="text-blue-500 hover:underline">Register</p>
            </Link>
          </div>

          <p className='text-center text-gray-500 mt-4'>
            Copyright © 2024 BrandRoute Media.com
          </p>
        </form>
      </div>
      {alert.show && <CustomAlert show={alert.show} icon={alert.icon} title={alert.title} message={alert.message} onClose={() => setAlert({ ...alert, show: false })} />}
    </div>
  );
};

export default Login;
