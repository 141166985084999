import React, { useState, useEffect } from 'react'
import Modal from '../components/ui/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { createWhitelistEntry, selectWhitelistLoading } from '../store/slice/WhitelistSlice'
import Swal from 'sweetalert2'
import backgroundImage from '../images/modalbg.png'

export default function CreateWhitelist({ onWhitelistCreated }) {
    const [open, setOpen] = useState(false)
    const [formData, setFormData] = useState({
        creatorName: '',
        clientId: '',
        date: new Date().toISOString().split('T')[0],
        channelName: '',
        channelUrl: ''
    })
    const [errors, setErrors] = useState({})

    const dispatch = useDispatch()
    const loading = useSelector(selectWhitelistLoading)

    useEffect(() => {
        const storedClientId = localStorage.getItem('clientID')
        const fullName = localStorage.getItem('fullName')
        if (storedClientId) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                clientId: storedClientId,
                creatorName: fullName
            }))
        }
    }, [])

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const validateForm = () => {
        const newErrors = {}
        if (!formData.channelName) newErrors.channelName = 'Channel Name is required'
        if (!formData.channelUrl) newErrors.channelUrl = 'Channel URL is required'
        return newErrors
    }

    const handleSubmit = async () => {
        const validationErrors = validateForm()
        const storedClientId = localStorage.getItem('clientID')
        const fullName = localStorage.getItem('fullName')
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors)
            return
        }

        try {
            await dispatch(createWhitelistEntry(formData)).unwrap()

            Swal.fire({
                icon: 'success',
                title: 'Whitelist Created Successfully!',
                text: 'The channel has been added to the whitelist.'
            })

            setFormData({
                creatorName: fullName || '',
                clientId: storedClientId || '',
                date: new Date().toISOString().split('T')[0],
                channelName: '',
                channelUrl: ''
            })

            setErrors({})
            setOpen(false)

            if (onWhitelistCreated) {
                onWhitelistCreated()
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error creating the whitelist entry. Please try again.'
            })
        }
    }

    return (
        <main className="App">
            <button
                className="transition ease-in-out hover:-translate-y-1 hover:scale-110 bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 p-2 text-white rounded-lg"
                onClick={() => setOpen(true)}
            >
                Create Whitelist
            </button>

            <Modal open={open} onClose={() => setOpen(false)}>
                <div
                    className="h-[28rem] bg-black border-green-500"
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                >
                    <div className="flex justify-between p-2">
                        <div className="p-2">
                            <h1 className="text-white font-semibold text-xl">Create Whitelist Request</h1>
                            <p className="text-neutral-200 text-sm">Submit Channel Details</p>
                        </div>
                    </div>
                    <div className="modal-body w-full px-4 py-2 flex flex-col items-center justify-center gap-2">
                        <div className="grid grid-cols-2 gap-2">
                            <label htmlFor="clientId" className="flex flex-col items-start gap-2 text-white">
                                Client ID
                                <input
                                    type="text"
                                    id="clientId"
                                    name="clientId"
                                    value={formData.clientId}
                                    readOnly
                                    className="border border-white text-gray-400 rounded-md p-1 bg-black"
                                />
                            </label>

                            <label htmlFor="creatorName" className="flex flex-col items-start gap-2 text-white">
                                Creator Name
                                <input
                                    type="text"
                                    id="creatorName"
                                    name="creatorName"
                                    value={formData.creatorName}
                                    readOnly
                                    className="border border-white rounded-md p-1 bg-black text-gray-400"
                                />
                            </label>

                            <label htmlFor="date" className="flex flex-col items-start gap-2">
                                Date
                                <input
                                    type="text"
                                    id="date"
                                    name="date"
                                    value={formData.date}
                                    readOnly
                                    className="border border-white rounded-md p-1 bg-black text-gray-400"
                                />
                            </label>
                            <label htmlFor="channelName" className="flex flex-col items-start gap-2 text-white">
                                Channel Name
                                <input
                                    type="text"
                                    id="channelName"
                                    name="channelName"
                                    value={formData.channelName}
                                    onChange={handleInputChange}
                                    className={`border ${
                                        errors.channelName ? 'border-red-500' : 'border-white'
                                    } rounded-md p-1 bg-black`}
                                />
                                {errors.channelName && (
                                    <span className="text-red-500 text-sm">{errors.channelName}</span>
                                )}
                            </label>

                            <label htmlFor="channelUrl" className="flex flex-col items-start gap-2 text-white">
                                Channel URL
                                <input
                                    type="text"
                                    id="channelUrl"
                                    name="channelUrl"
                                    value={formData.channelUrl}
                                    onChange={handleInputChange}
                                    className={`border ${
                                        errors.channelUrl ? 'border-red-500' : 'border-white'
                                    } rounded-md p-1 bg-black`}
                                />
                                {errors.channelUrl && <span className="text-red-500 text-sm">{errors.channelUrl}</span>}
                            </label>
                        </div>

                        <button
                            type="button"
                            onClick={handleSubmit}
                            className="btn mt-4 btn-primary bg-gradient-to-r from-sky-400 to-sky-800 w-full"
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </div>
            </Modal>
        </main>
    )
}
