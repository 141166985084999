import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

const initialState = {
    claimsData: [],
    channelNames: [],
    loading: false,
    error: null
};

// Fetch all claims
export const fetchClaimsData = createAsyncThunk(
    'claims/fetchClaimsData',
    async (_, thunkAPI) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found. Please login again.');
            }
            const response = await fetch(`${process.env.REACT_APP_API_KEY}/v1/api/getAllClaimsByUser`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const result = await response.json();
            if (result.status === 'success') {
                return result.data;
            } else {
                throw new Error('Failed to fetch claims data');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ errorMessage: error.message });
        }
    }
);

// Create claim request
export const createClaim = createAsyncThunk(
    'claims/createClaim',
    async (formData, thunkAPI) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found. Please login again.');
            }
            const response = await fetch(`${process.env.REACT_APP_API_KEY}/v1/api/createClaim`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const result = await response.json();
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue({ errorMessage: error.message });
        }
    }
);

// Fetch all visual channels
export const fetchChannelNames = createAsyncThunk(
    'claims/fetchChannelNames',
    async (_, thunkAPI) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found. Please login again.');
            }
            const response = await fetch(`${process.env.REACT_APP_API_KEY}/v1/api/visualChannels/channelNames`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const result = await response.json();
            if (result.status === 'success') {
                return result.data;
            } else {
                throw new Error('Failed to fetch channel names');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ errorMessage: error.message });
        }
    }
);

const claimsSlice = createSlice({
    name: 'claims',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchClaimsData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchClaimsData.fulfilled, (state, action) => {
                state.loading = false;
                state.claimsData = action.payload;
                state.error = null;
            })
            .addCase(fetchClaimsData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.errorMessage;
            })
            .addCase(createClaim.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createClaim.fulfilled, (state, action) => {
                state.loading = false;
                state.claimsData.push(action.payload);
                state.error = null;
            })
            .addCase(createClaim.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.errorMessage;
            })
            .addCase(fetchChannelNames.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchChannelNames.fulfilled, (state, action) => {
                state.loading = false;
                state.channelNames = action.payload;
                state.error = null;
            })
            .addCase(fetchChannelNames.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.errorMessage;
            });
    }
});

// Memoized selectors
const selectClaimsState = (state) => state.claims;
export const selectClaimsData = createSelector([selectClaimsState], (claims) => claims.claimsData);
export const selectChannelNames = createSelector([selectClaimsState], (claims) => claims.channelNames || []);
export const selectLoadingStatus = createSelector([selectClaimsState], (claims) => claims.loading);

export default claimsSlice.reducer;