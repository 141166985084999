import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    error: null,
    success: false,
    channels: [],
    cmsEntries: [], // Added cmsEntries to the initial state
};

export const createMCN = createAsyncThunk(
    'mcn/createMCN',
    async (formData, thunkAPI) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found. Please login again.');
            }

            const response = await fetch(`${process.env.REACT_APP_API_KEY}/v1/api/linkAndCreateChannel/create`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                return thunkAPI.rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getAllChannelsByUser = createAsyncThunk(
    'mcn/getAllChannelsByUser',
    async (_, thunkAPI) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found. Please login again.');
            }

            const response = await fetch(`${process.env.REACT_APP_API_KEY}/v1/api/getAlllinkAndCreateChannelByUser/user`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return thunkAPI.rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getCmsEntriesUser = createAsyncThunk(
    'mcn/getCmsEntriesUser',
    async (_, thunkAPI) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found. Please login again.');
            }

            const response = await fetch(`${process.env.REACT_APP_API_KEY}/v1/api/getCmsEntriesUser`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return thunkAPI.rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

const mcnSlice = createSlice({
    name: 'mcn',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(createMCN.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(createMCN.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(createMCN.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ? action.payload.error : 'Failed to connect to the server.';
                state.success = false;
            })
            .addCase(getAllChannelsByUser.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getAllChannelsByUser.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.channels = action.payload.data;
            })
            .addCase(getAllChannelsByUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ? action.payload.error : 'Failed to connect to the server.';
                state.success = false;
            })
            .addCase(getCmsEntriesUser.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getCmsEntriesUser.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.cmsEntries = action.payload.data; // Store the CMS entries in the state
            })
            .addCase(getCmsEntriesUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ? action.payload.error : 'Failed to connect to the server.';
                state.success = false;
            });
    },
});

export default mcnSlice.reducer;
