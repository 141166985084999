import React, { useState, useEffect } from 'react'
import Modal from '../components/ui/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { createSyndicateAuthorization, updateSyndicateInstructionPic } from '../store/slice/SyndicateSlice'
import bg from '../images/synbg.png'
import CustomAlert from '../components/ui/CustomAlert';

export default function SyndicationAuth() {
    const [open, setOpen] = useState(false)
    const [nextStepOpen, setNextStepOpen] = useState(false)
    const [formData, setFormData] = useState({
        date: new Date().toISOString().split('T')[0],
        channelName: '',
        youtubeChannelLink: '',
        clientId: ''
    })
    const [digitalSignature, setDigitalSignature] = useState(''); // Store the digital signature separately
    const [instructionPic, setInstructionPic] = useState(null)
    const [pdfUrl, setPdfUrl] = useState(null)
    const [syndicateId, setSyndicateId] = useState(null)
    const [alert, setAlert] = useState({ show: false, icon: '', title: '', message: '' });

    const dispatch = useDispatch()
    const { loading, success, error, syndicateData } = useSelector((state) => state.syndicate)

    useEffect(() => {
        const storedClientId = localStorage.getItem('clientID')
        const storedName = localStorage.getItem('fullName')
        const digitalSignature = localStorage.getItem('digitalSignature')
        if (storedClientId) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                clientId: storedClientId,
                Name: storedName
            }))
            setDigitalSignature(digitalSignature); // Set digital signature separately
        }
    }, [])

    useEffect(() => {
        if (success && syndicateData) {
            setPdfUrl(syndicateData.pdfUrl)
            setSyndicateId(syndicateData._id)
        }
    }, [success, syndicateData])

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleFileChange = (e) => {
        setInstructionPic(e.target.files[0])
    }

    // Function to validate the form data
    const validateFormData = () => {
        if (!formData.channelName || !formData.youtubeChannelLink || !formData.clientId || !digitalSignature) {
            setAlert({
                show: true,
                icon: 'error',
                title: 'Validation Error',
                message: 'Please fill in all the required fields before proceeding.',
            });
            return false;
        }
        return true;
    };

    const handleSubmitFirstModal = () => {
        setOpen(false)
        setNextStepOpen(true)
    }

    const handleSubmitSecondModal = () => {
        if (syndicateId && instructionPic) {
            const formDataForUpload = new FormData()
            formDataForUpload.append('instructionPic', instructionPic)
            dispatch(updateSyndicateInstructionPic({ id: syndicateId, formData: formDataForUpload }))
            setAlert({
                show: true,
                icon: 'success',
                title: 'Thank You!!',
                message: 'Syndicate Authentication Created Successfully!',
            });

            setNextStepOpen(false)
            setFormData({
                date: new Date().toISOString().split('T')[0],
                channelName: '',
                youtubeChannelLink: '',
                clientId: formData.clientId
            })
            setInstructionPic(null)
        }
    }

    const handleCreateSyndicate = () => {
        if (!validateFormData()) return; // Return if validation fails

        const formDataForCreate = {
            creatorSignature: digitalSignature, // Use digitalSignature from separate state
            date: formData.date,
            channelName: formData.channelName,
            youtubeChannelLink: formData.youtubeChannelLink,
            clientId: formData.clientId
        }

        dispatch(createSyndicateAuthorization(formDataForCreate))
        handleSubmitFirstModal()
    }

    const closeAlert = () => {
        setAlert({ ...alert, show: false });
    };

    return (
        <main className="App">
            <button className="transition ease-in-out  hover:-translate-y-1 hover:scale-110   p-2 text-white rounded-lg  bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500" onClick={() => setOpen(true)}>
                Add New Channel
            </button>

            <Modal open={open} onClose={() => setOpen(false)}>
                <div
                    className=" w-[60vw] lg:w-[30vw]  bg-black border-green-500"
                    style={{
                        backgroundImage: `url(${bg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                >
                    <div className="text-white flex flex-col justify-center items-center px-4 gap-3">
                        <h1 className="text-xl mt-2 font-bold mb-6">
                            SYNDICATION <span className="text-blue-500">AUTHORIZATION</span>
                        </h1>

                        <p className="mt-4">
                            I, <span className="text-blue-500">{formData.Name}</span> confirm that BRANDROUTE MEDIA
                            PVT LTD Is authorized to License my Short contents to YT Media Entertainment for
                            monetization online. I agree to take a photo holding up this signed letter to confirm its
                            authenticity. This notice will remain valid until I provide written notice stating otherwise
                            to <span className="text-blue-500">admin@ytmedia.com</span> .
                        </p>
                        <div className="flex  flex-col flex-start ">
                            <div className="text-white grid grid-cols-2 gap-4">
                                <label htmlFor="channelName" className="flex flex-col gap-2">
                                    Channel Name
                                    <input
                                        type="text"
                                        name="channelName"
                                        value={formData.channelName}
                                        onChange={handleInputChange}
                                        className="border border-white p-1 rounded-md bg-transparent"
                                    />
                                </label>
                                <label htmlFor="youtubeChannelLink" className="flex flex-col gap-2">
                                    YouTube Channel Link
                                    <input
                                        type="text"
                                        name="youtubeChannelLink"
                                        value={formData.youtubeChannelLink}
                                        onChange={handleInputChange}
                                        className="border border-white p-1 rounded-md bg-transparent"
                                    />
                                </label>

                                <label htmlFor="creatorSignature" className="flex flex-col gap-2">
                                    Creator's Signature
                                    <div className="mt-2 bg-white">
                                        <img src={digitalSignature} alt="Digital Signature" /> {/* Use digitalSignature */}
                                    </div>
                                </label>
                                <label htmlFor="date" className="flex flex-col gap-2">
                                    Date
                                    <input
                                        type="date"
                                        name="date"
                                        value={formData.date}
                                        onChange={handleInputChange}
                                        className="border border-white p-1 rounded-md bg-transparent"
                                    />
                                </label>
                            </div>
                            
                        </div>
                        <div className="flex items-center justify-center w-full p-4">
                            <button
                                className="bg-gradient-to-r from-sky-400 to-sky-800 w-full p-2 text-white rounded-md"
                                onClick={handleCreateSyndicate}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* Second Modal */}
            {nextStepOpen && (
                <Modal open={nextStepOpen} onClose={() => setNextStepOpen(false)}>
                    <div className="w-[60vw] lg:w-[30vw] h-[38rem] bg-black border-green-500 p-4 rounded-md">
                        <div className="text-white flex flex-col justify-center items-center gap-3">
                            <h1>Play this Video & Follow the instructions.</h1>
                            <iframe
                                width="320"
                                height="200"
                                frameBorder="0"
                                allowFullScreen
                                className="border rounded-md border-emerald-300 p-1"
                            ></iframe>
                            <p>Agreement File (.pdf)</p>
                            {pdfUrl && (
                                <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                                    <button className="bg-blue-500 text-white p-2 rounded-md">Download</button>
                                </a>
                            )}
                            <label className="flex flex-col mt-2">
                                <p className="text-sm">
                                    Attach the downloaded file with your face as per the instruction video (.jpg) (max 500KB){' '}
                                    <span className="text-rose-600 font-bold">*</span>
                                </p>
                                <div className="flex items-center border border-gray-300 mt-2 rounded-md p-2">
                                    <input
                                        type="file"
                                        accept=".pdf,.jpg,.jpeg,.png"
                                        name="instructionPic"
                                        onChange={handleFileChange}
                                        className="block w-full text-white"
                                    />
                                </div>
                            </label>
                            <div className="flex justify-center w-full p-4">
                                <button
                                    className="bg-gradient-to-r from-sky-400 to-sky-800 p-2 w-[8rem]"
                                    onClick={handleSubmitSecondModal} 
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {alert.show && (
                <CustomAlert
                    show={alert.show}
                    icon={alert.icon}
                    title={alert.title}
                    message={alert.message}
                    onClose={closeAlert}
                />
            )}
        </main>
    )
}
