import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaDollarSign } from 'react-icons/fa';
import { fetchDashboardStats, selectDashboardStats, selectLoadingStatus, selectError } from '../store/slice/dashSlice'; 
import Loader from '../components/shared/Loader';
import backgroundImage from '../images/boxbg1.jpg'

const Status = () => {
    const dispatch = useDispatch();
    const dashboardStats = useSelector(selectDashboardStats);
    const loading = useSelector(selectLoadingStatus);
    const error = useSelector(selectError);

    useEffect(() => {
        dispatch(fetchDashboardStats());
    }, [dispatch]);

    if (loading === 'loading') {
        return <Loader />;
    }

  

    if (!dashboardStats) {
        return <div>No data available</div>;
    }

    return (
        <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row flex-wrap gap-2">
            <BoxWrapper>
                <div className="pl-4">
                    <span className="text-sm  font-bold">Total Active Assets</span>
                    <div className="flex items-center">
                        <strong className="text-xl  font-semibold">
                            {dashboardStats.totalActiveAssets}
                        </strong>
                        <span className="text-sm text-green-500  pl-2">
                            +{dashboardStats.percentageChanges.totalActiveAssets}% From Last Months
                        </span>
                    </div>
                </div>
            </BoxWrapper>
            <BoxWrapper>
                <div className="pl-4">
                    <span className="text-sm  font-bold">Total Channel's (Visual Claim)</span>
                    <div className="flex items-center">
                        <strong className="text-xl  font-semibold">
                            {dashboardStats.totalChannels}
                        </strong>
                        <span className="text-sm text-green-500  pl-2">
                            +{dashboardStats.percentageChanges.totalChannels}% From Last Months
                        </span>
                    </div>
                </div>
            </BoxWrapper>
            <BoxWrapper>
                <div className="pl-4">
                    <span className="text-sm  font-bold">MCN & CMS Channels</span>
                    <div className="flex items-center">
                        <strong className="text-xl font-semibold">
                            {dashboardStats.totalMcnAndCmsChannels}
                        </strong>
                        <span className="text-sm text-green-500  pl-2">
                            +{dashboardStats.percentageChanges.totalMcnAndCmsChannels}% From Last Months
                        </span>
                    </div>
                </div>
            </BoxWrapper>
            <BoxWrapper>
                <div className="pl-4">
                    <span className="text-sm  font-bold">Monthly Revenue</span>
                    <div className="flex items-center">
                        <strong className="text-xl  font-semibold flex justify-center items-center">
                            <FaDollarSign className="text-green-600" />
                            {dashboardStats.monthlyRevenue}
                        </strong>
                        <span className="text-sm text-green-500  pl-2">
                            +{dashboardStats.percentageChanges.monthlyRevenue}% From Last Months
                        </span>
                    </div>
                </div>
            </BoxWrapper>
        </div>
    );
};

function BoxWrapper({ children }) {
    return <div className=" transition   ease-in-out bg-cover bg-center  hover:-translate-y-1 hover:scale-110  rounded-lg p-4 flex-1 border border-gray-200 flex items-center  text-gray-500 "
    style={{ backgroundImage: `url(${backgroundImage})`,
    
      boxShadow: '4px 4px 4px rgba(56, 189, 248, 0.4)',
     }}
    >{children}</div>;
  }
export default Status;
