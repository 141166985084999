import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    whitelistData: [],
    loading: false,
    error: null,
    creating: false,
    success: false
}

export const fetchWhitelistData = createAsyncThunk('whitelist/fetchWhitelistData', async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_KEY}/v1/api/getAllwhitelistByUser`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        })
        return response.data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const createWhitelistEntry = createAsyncThunk(
    'whitelist/createWhitelistEntry',
    async (whitelistData, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_KEY}/v1/api/whitelistCreate`, whitelistData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            })
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

const whitelistSlice = createSlice({
    name: 'whitelist',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchWhitelistData.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(fetchWhitelistData.fulfilled, (state, action) => {
                state.loading = false
                state.whitelistData = action.payload
            })
            .addCase(fetchWhitelistData.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload.message
            })

            .addCase(createWhitelistEntry.pending, (state) => {
                state.creating = true
                state.error = null
                state.success = false
            })
            .addCase(createWhitelistEntry.fulfilled, (state, action) => {
                state.creating = false
                state.success = true
            })
            .addCase(createWhitelistEntry.rejected, (state, action) => {
                state.creating = false
                state.error = action.payload.message
                state.success = false
            })
    }
})

export const selectWhitelistData = (state) => state.whitelist.whitelistData
export const selectWhitelistLoading = (state) => state.whitelist.loading
export const selectCreateWhitelistLoading = (state) => state.whitelist.creating
export const selectCreateWhitelistSuccess = (state) => state.whitelist.success
export const selectCreateWhitelistError = (state) => state.whitelist.error

export default whitelistSlice.reducer
