import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
    claimReleaseData: [],
    loading: false,
    error: null
}

export const fetchClaimReleaseData = createAsyncThunk('claimRelease/fetchData', async (_, thunkAPI) => {
    try {
        const token = localStorage.getItem('token')
        if (!token) {
            throw new Error('No token found. Please login again.')
        }

        const response = await fetch(`${process.env.REACT_APP_API_KEY}/v1/api/claimReleaseRequest/user`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (!response.ok) {
            throw new Error('Failed to fetch claim release data')
        }

        const data = await response.json()
        return data.data
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
    }
})

export const createClaimRelease = createAsyncThunk('claimRelease/create', async (payload, thunkAPI) => {
    try {
        const token = localStorage.getItem('token')
        if (!token) {
            throw new Error('No token found. Please login again.')
        }

        const response = await fetch(`${process.env.REACT_APP_API_KEY}/v1/api/claimReleaseRequest`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        })

        if (!response.ok) {
            throw new Error('Failed to create claim release request')
        }

        const data = await response.json()
        return data.data
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
    }
})

const releaseSlice = createSlice({
    name: 'claimRelease',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchClaimReleaseData.pending, (state) => {
            state.loading = true
        })

        builder.addCase(fetchClaimReleaseData.fulfilled, (state, action) => {
            state.loading = false
            state.claimReleaseData = action.payload
            state.error = null
        })

        builder.addCase(fetchClaimReleaseData.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(createClaimRelease.pending, (state) => {
            state.loading = true
        })

        builder.addCase(createClaimRelease.fulfilled, (state, action) => {
            state.loading = false
            state.claimReleaseData.push(action.payload)
            state.error = null
        })

        builder.addCase(createClaimRelease.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    }
})

export default releaseSlice.reducer
