import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPaymentReport } from '../store/slice/paySlice'
import { FaDollarSign } from 'react-icons/fa'
import Loader from '../components/shared/Loader'
const backgroundImage = require('../images/Backround.jpg')

const ReportsPayments = () => {
    const [searchDate, setSearchDate] = useState('')
    const dispatch = useDispatch()
    const { data, status, error } = useSelector((state) => state.pay)

    const handleSearch = () => {
        dispatch(fetchPaymentReport(searchDate))
    }



 

   

    return (
        <div className="flex  w-full bg-cover bg-center justify-center items-center gap-4 shadow-lg"
        style={{ backgroundImage: `url(${backgroundImage})`}}
        >
            <div className="flex flex-col w-full">
                <div className="flex mt-4 justify-center mb-4">
                    <input
                        type="month"
                        value={searchDate}
                        onChange={(e) => setSearchDate(e.target.value)}
                        placeholder="Enter date (YYYY-MM)"
                        className="border border-gray-300 rounded p-2"
                    />
                    <button onClick={handleSearch} className="transition ease-in-out  hover:-translate-y-1 hover:scale-110  ml-2 bg-blue-500 text-white p-2 rounded">
                        Search
                    </button>
                </div>
                {status === 'loading' && (
                   
                        <Loader />
                
                )}
                {status === 'failed' && (
                    <div className="text-red-500 text-center ">
                      <p>No Reprt Found</p>
                    </div>
                )}
                {status === 'succeeded' && data && (
                    <div className="flex  items-center justify-center flex-wrap">
                        <div className="bg-white w-[80%] px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1">
                            <h2 className="text-lg font-semibold bg-customGreen text-white p-3">
                                Visual Claims ({data.month} - {data.year})
                            </h2>
                            <div className="border-x border-gray-200 rounded-sm ">
                                <table className="w-full text-gray-700 table-auto ">
                                    <thead className="bg-black text-white">
                                        <tr>
                                            <th>
                                                <div className="flex items-center">
                                                    Date
                                                    <button
                                                        className="ml-2 focus:outline-none"
                                                        
                                                    >
                                                       
                                                    </button>
                                                </div>
                                              
                                            </th>
                                            <th className='text-center'>Channel Name</th>
                                            <th className='text-center'>View</th>
                                            <th className='text-center'>
                                            Revenue
                                               
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.visualClaims.map((claim) => (
                                            <tr key={claim._id}>
                                                <td>{new Date(claim.date).toLocaleDateString()}</td>
                                                <td className='text-center'>{claim.channelName}</td>
                                                <td className='text-center'>{claim.view}</td>
                                                <td className="flex justify-center items-center">
                                                    <FaDollarSign className="text-customGreen" />
                                                    {claim.revenue}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr className="bg-black text-white">
                                            <td className="text-right bg-blue-600  px-3 rounded-sm h-[100%]">
                                                Subtotal
                                            </td>
                                            <td></td>
                                            <td className='text-center'>{data.subtotals.visualClaims.views}</td>
                                            <td className="flex justify-center items-center">
                                                <FaDollarSign className="text-customGreen" />
                                                {data.subtotals.visualClaims.revenue}
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className="px-4 pt-3 bg-white pb-4 w-[80%] rounded-sm border border-gray-200 flex-1">
                            <h2 className="text-lg font-semibold bg-customGreen text-white p-3">
                                MCN Channels ({data.month} - {data.year})
                            </h2>
                            <div className="border-x border-gray-200 rounded-sm ">
                                <table className="w-full text-gray-700 table-auto ">
                                    <thead className="bg-black text-white">
                                        <tr>
                                            <th>
                                                <div className="flex items-center">
                                                    Date
                                                    <button
                                                        className="ml-2 focus:outline-none"
                                                       
                                                    >
                                                       
                                                    </button>
                                                </div>
                                               
                                            </th>
                                            <th className='text-center'>Channel Name</th>
                                            <th className='text-center'>
                                            Revenue
                                                
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.mcnChannels.map((channel) => (
                                            <tr key={channel._id}>
                                                <td>{new Date(channel.date).toLocaleDateString()}</td>
                                                <td className='text-center'>{channel.channelName}</td>
                                                <td className="flex justify-center items-center">
                                                    <FaDollarSign className="text-customGreen" />
                                                    {channel.revenue}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr className="bg-black text-white">
                                            <td className="text-right bg-blue-600  px-3 rounded-sm h-[100%]">
                                                Subtotal
                                            </td>
                                            <td></td>
                                            <td className="flex justify-center items-center">
                                                <FaDollarSign className="text-customGreen" />
                                                {data.subtotals.mcnChannels.revenue}
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ReportsPayments
