import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  registrationSuccess: false,
  formData: {},
  regionData: {},
  userDetails: {},
  token: null,
  clientID: null,
};

export const registerUser = createAsyncThunk('auth/registerUser', async (formDataPayload, thunkAPI) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_KEY}/v1/api/registerUser`, {
      method: 'POST',
      body: formDataPayload,
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const loginUser = createAsyncThunk('auth/loginUser', async ({ email, password }, thunkAPI) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_KEY}/v1/api/user/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Network response was not ok');
    }

    const data = await response.json();

    localStorage.setItem('token', data.data.token);
    localStorage.setItem('clientID', data.data.clientID);
    localStorage.setItem('profile_img', data.data.profile_img);
    localStorage.setItem('fullName', data.data.fullName);
    localStorage.setItem('digitalSignature', data.data.digitalSignature);

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const fetchUserDetails = createAsyncThunk('auth/fetchUserDetails', async (_, thunkAPI) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_KEY}/v1/api/user/details`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const updateProfileImage = createAsyncThunk('auth/updateProfileImage', async (profileImg, thunkAPI) => {
  try {
    const formData = new FormData();
    formData.append('profile_img', profileImg);

    const response = await fetch(`${process.env.REACT_APP_API_KEY}/v1/api/uploadProfileImg`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setRegionData: (state, action) => {
      state.regionData = action.payload;
    },
    clearAuthState: (state) => {
      state.loading = false;
      state.error = null;
      state.registrationSuccess = false;
      state.formData = {};
      state.regionData = {};
      state.userDetails = {};
      state.token = null;
      state.clientID = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.registrationSuccess = false;
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.registrationSuccess = true;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.registrationSuccess = false;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.registrationSuccess = false;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.registrationSuccess = true;
        state.token = action.payload.data.token;
        state.clientID = action.payload.data.clientID;
        state.userDetails = {
          profile_img: action.payload.data.profile_img,
          fullName: action.payload.data.fullName,
          digitalSignature: action.payload.data.digitalSignature,
        };
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.registrationSuccess = false;
      })
      .addCase(fetchUserDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.userDetails = action.payload.data;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateProfileImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfileImage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // if (state.userDetails) {
        //   state.userDetails.profile_img = action.payload.data.profile_img;
        // }
      })
      .addCase(updateProfileImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setFormData, setRegionData, clearAuthState } = authSlice.actions;

export default authSlice.reducer;
