import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../store/slice/AuthSlice'
import MusicDisSlice from './slice/MusicDisSlice'
import VisualSlice from './slice/VisualSlice'

import WhitelistSlice from './slice/WhitelistSlice'
import SyndicateSlice from './slice/SyndicateSlice'
import ReleaseSlice from './slice/ReleaseSlice'
import mcnSlice from './slice/mcnSlice'
import NotifiSlice from './slice/NotifiSlice'
import paySlice from './slice/paySlice'
import dashSlice from './slice/dashSlice'
import ForgotSlice from './slice/ForgotSlice'

const store = configureStore({
    reducer: {
        auth: authReducer,
        client: MusicDisSlice,
        claims: VisualSlice,

        whitelist: WhitelistSlice,
        syndicate: SyndicateSlice,
        release: ReleaseSlice,
        mcn: mcnSlice,
        notifications:NotifiSlice,
        pay:paySlice,
        dashboard:dashSlice,
        forgot:ForgotSlice
    }
})

export default store
