// RegionSelect.js
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { CountrySelect } from 'react-country-state-city'
import 'react-country-state-city/dist/react-country-state-city.css'
import { setRegionData } from '../../store/slice/AuthSlice'
import toast from 'react-hot-toast'

const RegionSelect = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [region, setRegion] = useState('')
    const formData = useSelector((state) => state.auth.formData)

    const handleCountryChange = (selectedCountry) => {
        setRegion(selectedCountry.name)
    }

    const handleNextClick = () => {
        if (!region) {
            toast.error('Please select a region before proceeding.')
            return
        }
        const updatedRegionData = { region }
        dispatch(setRegionData(updatedRegionData))
        navigate('/term', { state: { regionData: updatedRegionData } })
    }


    return (
        <div className="w-full h-screen flex flex-col items-center justify-center bg-black">
            <form className="bg-white shadow-md flex gap-4 rounded h-[90vh] w-[70vw] lg:w-[800px]">
                <div className="w-full md:lg-1/2 lg:w-1/2 flex flex-col px-8 pt-6 pb-8 mb-4">
                    <h3 className="font-poppins text-20px font-bold leading-24px tracking-0.012em text-left mb-6 text-center">
                        Select Your Region <span className="text-rose-600 font-bold">*</span>
                    </h3>
                    <p className="font-poppins text-md text-center mb-12">
                        Choosing the right region is a pivotal decision that can significantly impact various aspects.
                    </p>
                    <div className="mb-56">
                        <CountrySelect
                            id="country"
                            onChange={handleCountryChange}
                            placeHolder="Select Country"
                            defaultOptionLabel={region || 'Select Country'}
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            className="bg-black w-full text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleNextClick}
                        >
                            Next
                        </button>
                    </div>
                </div>
                <div
                    className="w-[0] md:w-1/2 lg:w-1/2"
                    style={{
                        backgroundImage: 'url(assets/bgform.png)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                ></div>
            </form>
        </div>
    )
}

export default RegionSelect
