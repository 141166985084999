import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWhitelistData, selectWhitelistData, selectWhitelistLoading } from '../store/slice/WhitelistSlice';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import CreateWhitelist from '../components/CreateWhitelist';
import Loader from '../components/shared/Loader';
import CustomAlert from '../components/ui/CustomAlert';
const backgroundImage = require('../images/Backround.jpg')

const Whitelist = () => {
    const dispatch = useDispatch();
    const whitelistData = useSelector(selectWhitelistData);
    const loading = useSelector(selectWhitelistLoading);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [alert, setAlert] = useState({ show: false, icon: '', title: '', message: '' });

    useEffect(() => {
        dispatch(fetchWhitelistData());
    }, [dispatch]);

    if (loading === true) {
        return <Loader />;
    }

    if (!whitelistData) {
        return <div>No data available</div>;
    }

    const handleNextPage = () => {
        if (currentPage * itemsPerPage < whitelistData.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleWhitelistUpdate = () => {
        dispatch(fetchWhitelistData());
       
    };

    const closeAlert = () => {
        setAlert({ ...alert, show: false });
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentData = whitelistData.slice(startIndex, startIndex + itemsPerPage);

    const startRow = (currentPage - 1) * itemsPerPage + 1;
    const endRow = Math.min(currentPage * itemsPerPage, whitelistData.length);

    return (
        <div>
            <div className="flex flex-wrap  justify-between bg-cover bg-center shadow-lg rounded-lg"
              style={{ backgroundImage: `url(${backgroundImage})`}}
            >
                <div className="flex flex-col p-2">
                    <h1 className="poppins text-2xl font-semibold">Whitelist Request</h1>
                    <p className="text-[16px] font-normal">Keep Track of video Progress and record...</p>
                </div>
                <div className="flex gap-4 p-4 flex-wrap">
                    <CreateWhitelist onWhitelistCreated={handleWhitelistUpdate} />
                </div>
                <div className="mt-2 w-full px-4 ">
                    <Table className=''>
                        <Thead className='text-white bg-blue-600'>
                            <Tr>
                                <Th>Client ID</Th>
                                <Th>Creator Name</Th>
                                <Th>Channel Name</Th>
                                <Th>Channel URL</Th>
                                <Th className='text-center'>Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentData.map((claim) => (
                                <Tr key={claim._id}>
                                    <Td>{claim.clientId}</Td>
                                    <Td>{claim.creatorName}</Td>
                                    <Td>{claim.channelName}</Td>
                                    <Td>
                                        <a href={claim.channelUrl} target="_blank" rel="noopener noreferrer">
                                            Channel URL
                                        </a>
                                    </Td>
                                    <Td className='flex items-center justify-center'>
                                        <button
                                            className={`h-8 font-semibold w-24 rounded ${
                                                claim.status === 'Rejected' ? 'bg-red-500' : 'bg-blue-500'
                                            } text-white p-1`}
                                        >
                                            {claim.status}
                                        </button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    <div className="mt-2">
                        <p className="mt-6 text-red-500 text-gray-600">
                            Showing {startRow}-{endRow} of {whitelistData.length} rows selected
                        </p>
                    </div>
                </div>
                <div className="flex justify-between w-full p-4">
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="bg-blue-500 text-white p-2 rounded disabled:bg-gray-400"
                    >
                        Previous
                    </button>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage * itemsPerPage >= whitelistData.length}
                        className="bg-blue-500 text-white p-2 rounded disabled:bg-gray-400"
                    >
                        Next
                    </button>
                </div>
            </div>

           
        </div>
    );
};

export default Whitelist;
