import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { fetchClientData, createClaimRequest } from '../store/slice/MusicDisSlice';
import CustomAlert from '../components/ui/CustomAlert';


const MusicDistribution = () => {
    const dispatch = useDispatch();
    const clientId = useSelector((state) => state.client.clientId);
    const fullName = useSelector((state) => state.client.fullName);
    const date = useSelector((state) => state.client.date);
    const loading = useSelector((state) => state.client.loading);

    const initialFormData = {
        creatorName: '',
        contentIdBy: '',
        videoUrl: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [alert, setAlert] = useState({ show: false, icon: '', title: '', message: '' });

    useEffect(() => {
        dispatch(fetchClientData());
    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            creatorName: fullName,
            clientId: clientId,
            contentIdBy: formData.contentIdBy,
            videoUrl: formData.videoUrl
        };

        try {
            await dispatch(createClaimRequest(payload)).unwrap();
            setAlert({
                show: true,
                icon: 'success',
                title: 'Thank You!!',
                message: 'Claim release submitted successfully!',
            });

            setFormData(initialFormData);
        } catch (error) {
            setAlert({
                show: true,
                icon: 'error',
                title: 'Error',
                message: 'Error creating music claim release. Please try again.',
            });
        }
    };

    const closeAlert = () => {
        setAlert({ ...alert, show: false });
    };

    return (
        <div className="musicd text-white h-[100%] flex justify-center items-center">
            <div className="w-[100%] lg:w-[32rem] md:w-[60%] flex flex-col gap-4 px-8">
                <div>
                    <h1 className="text-xl font-bold">Music Claim Release Request</h1>
                    <p>Submit video URL for releasing claim.</p>
                </div>
                <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-2">
                        <label htmlFor="creatorName">
                            Creator Name <span className="text-blue-500 text-md">*</span>
                        </label>
                        <input
                            type="text"
                            className="bg-transparent text-gray-400 font-bold border border-gray-500 py-2 rounded-md"
                            name="creatorName"
                            value={fullName}
                            readOnly
                        />
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
                        <div className="flex flex-col gap-2 ">
                            <label htmlFor="clientId">Client Id</label>
                            <input
                                type="text"
                                className="bg-transparent text-gray-400 font-bold border border-gray-500 py-2 rounded-md"
                                name="clientId"
                                value={clientId}
                                readOnly
                            />
                        </div>
                        <div className="flex flex-col gap-2 ">
                            <label htmlFor="date">Date</label>
                            <input
                                type="text"
                                className="bg-transparent text-gray-400 font-bold border border-gray-500 py-2 rounded-md"
                                name="date"
                                value={date}
                                readOnly
                            />
                        </div>
                        <div className="flex flex-col gap-2 ">
                            <label htmlFor="contentIdBy">Content Id by</label>
                            <input
                                type="text"
                                className="bg-transparent border border-gray-500 py-2 rounded-md"
                                name="contentIdBy"
                                value={formData.contentIdBy}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <label htmlFor="videoUrl">
                            Video URL <span className="text-blue-500 text-md">*</span>
                        </label>
                        <input
                            type="text"
                            className="bg-transparent border border-gray-500 py-2 rounded-md"
                            name="videoUrl"
                            value={formData.videoUrl}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <Button type="submit" className="p-2 font-bold bg-gradient-to-r from-sky-400 to-sky-800 w-[10rem] text-white">
                        {loading ? 'Submitting...' : 'Submit'}
                    </Button>
                </form>
            </div>
            {alert.show && (
                <CustomAlert
                    show={alert.show}
                    icon={alert.icon}
                    title={alert.title}
                    message={alert.message}
                    onClose={closeAlert}
                />
            )}
        </div>
    );
};

export default MusicDistribution;
