import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserDetails, updateProfileImage } from '../store/slice/AuthSlice'; 
import { FiEdit } from 'react-icons/fi';
import Avatar from '../images/profile.png';
import Loader from '../components/shared/Loader';

const Profile = () => {
  const dispatch = useDispatch();
  const { userDetails, loading, error } = useSelector((state) => state.auth);
  const [profileImage, setProfileImage] = useState(Avatar);

  useEffect(() => {
    dispatch(fetchUserDetails());
  }, [dispatch]);

  useEffect(() => {
    if (userDetails.profile_img) {
      setProfileImage(userDetails.profile_img);
    } else {
      setProfileImage(Avatar);
    }
  }, [userDetails]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setProfileImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      dispatch(updateProfileImage(file));
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="w-full h-screen bg-gray-100 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
        <div className="flex flex-col items-center">
          <div className="relative flex items-center justify-center w-full bg-blue-400 p-2 mb-4">
            <img
              className="w-24 h-24 rounded-full border border-black"
              src={profileImage}
              alt="Profile"
            />
            <label htmlFor="file-input" className="absolute top-0 right-0 p-1 bg-white rounded-full cursor-pointer">
              <FiEdit size={24} />
            </label>
            <input
              id="file-input"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleImageChange}
            />
          </div>
          <h2 className="text-2xl font-semibold mb-2">{userDetails.fullName}</h2>
          <div className="flex flex-col flex-start gap-2">
            <p className="text-gray-600 mb-1"><span className='font-bold'>ID</span> : {userDetails.clientID}</p>
            <p className="text-gray-600 mb-1"><span className='font-bold'>Legal Name</span> : {userDetails.legalName}</p>
            <p className="text-gray-600 mb-1"><span className='font-bold'>Label Name</span> : {userDetails.labelName}</p>
            <p className="text-gray-600 mb-1"><span className='font-bold'>Email</span> : {userDetails.email}</p>
            <p className="text-gray-600 mb-1"><span className='font-bold'>Phone</span> : {userDetails.mobileNumber}</p>
            <p className="text-gray-600 mb-1"><span className='font-bold'>Country</span> : {userDetails.region}</p>
            
          
          </div>
          <div className="mt-4">
            <a
              href={userDetails.governmentIdAttachment}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              ID Proof ({userDetails.governmentId})
            </a>
          </div>
          <div className="mt-2">
            <a
              href={userDetails.governmentAddressAttachment}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              Address Proof ({userDetails.governmentAddress})
            </a>
          </div>
          <div className="mt-2">
            <img src={userDetails.digitalSignature} alt="Digital Signature" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
