import React, { useState, useEffect } from 'react';
import Modal from '../components/ui/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { createClaim, fetchChannelNames, selectChannelNames, selectLoadingStatus } from '../store/slice/VisualSlice';
import Selection from '../components/ui/Selection';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const backgroundImage = require('../images/modalbg.png');

export default function StartClaim({ onClaimCreated }) {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        creatorName: '',
        clientId: '',
        date: new Date().toISOString().split('T')[0],
        videoTitle: '',
        channelName: '',
        videoUrl: '',
        priority: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const [selectionKey, setSelectionKey] = useState(0); 
    const dispatch = useDispatch();
    const channelNames = useSelector(selectChannelNames);
    const loading = useSelector(selectLoadingStatus);

    useEffect(() => {
        dispatch(fetchChannelNames());
        const storedClientId = localStorage.getItem('clientID');
        const fullName = localStorage.getItem('fullName');
        if (storedClientId) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                clientId: storedClientId,
                creatorName: fullName
            }));
        }
    }, [dispatch]);

    const validateForm = () => {
        const errors = {};
        if (!formData.videoTitle) errors.videoTitle = 'Video Title is required';
        if (!formData.videoUrl) errors.videoUrl = 'Video URL is required';
        if (!formData.channelName) errors.channelName = 'Channel Name is required';
        if (!formData.priority) errors.priority = 'Priority is required';
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSelectChange = (selectedPriority) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            priority: selectedPriority
        }));
    };

    const handleChannelSelect = (selectedChannelName) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            channelName: selectedChannelName
        }));
    };

    const handleSubmit = async () => {
        if (!validateForm()) return; 

        try {
            await dispatch(createClaim(formData)).unwrap();
            setOpen(false);

            const storedClientId = localStorage.getItem('clientID');
            const fullName = localStorage.getItem('fullName');

            setFormData({
                creatorName: fullName || '',
                clientId: storedClientId || '',
                date: new Date().toISOString().split('T')[0],
                videoTitle: '',
                channelName: '',
                videoUrl: '',
                priority: ''
            });
            setSelectionKey(prevKey => prevKey + 1); 

            if (onClaimCreated) {
                onClaimCreated();
            }

        
            MySwal.fire({
                icon: 'success',
                title: 'Claim Submitted',
                text: 'Your visual claim has been submitted successfully!',
            });
        } catch (error) {
            
            MySwal.fire({
                icon: 'error',
                title: 'Submission Failed',
                text: error.message || 'There was an error submitting the claim. Please try again.',
            });
        }
    };


    return (
        <main className="App">
            <button className="transition ease-in-out hover:-translate-y-1 hover:scale-110 p-2 text-white rounded-lg bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500" onClick={() => setOpen(true)}>
                Start Claim
            </button>

            <Modal open={open} onClose={() => setOpen(false)}>
                <div
                    className="rounded-md h-[27rem] bg-black border-green-500"
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                >
                    <div className="flex justify-between p-2">
                        <div className="p-2">
                            <h1 className="text-white font-semibold text-xl">Start Video Claim</h1>
                            <p className="text-neutral-200 text-sm">Submit Video URL For Video Claim</p>
                        </div>
                    </div>
                    <div className="modal-body w-full px-4 py-2 flex flex-col items-center justify-center gap-2">
                        <div className="grid grid-cols-2 gap-2 text-white">
                            <label htmlFor="clientId" className="flex flex-col items-start gap-2">
                                Client ID
                                <input
                                    type="text"
                                    id="clientId"
                                    name="clientId"
                                    value={formData.clientId}
                                    className="border border-white text-gray-400 rounded-md p-1 bg-black"
                                    readOnly
                                />
                            </label>

                            <label htmlFor="creatorName" className="flex flex-col items-start gap-2">
                                Creator Name
                                <input
                                    type="text"
                                    id="creatorName"
                                    name="creatorName"
                                    value={formData.creatorName}
                                    className="border border-white text-gray-400 rounded-md p-1 bg-black"
                                    readOnly
                                />
                            </label>

                            <label htmlFor="videoTitle" className="flex flex-col items-start gap-2">
                                Video Title
                                <input
                                    type="text"
                                    id="videoTitle"
                                    name="videoTitle"
                                    value={formData.videoTitle}
                                    onChange={handleInputChange}
                                    className={`border rounded-md p-1 bg-black ${formErrors.videoTitle ? 'border-red-500' : 'border-white'}`}
                                />
                                {formErrors.videoTitle && <span className="text-red-500 text-sm">{formErrors.videoTitle}</span>}
                            </label>

                            <label htmlFor="videoUrl" className="flex flex-col items-start gap-2">
                                Video URL
                                <input
                                    type="text"
                                    id="videoUrl"
                                    name="videoUrl"
                                    value={formData.videoUrl}
                                    onChange={handleInputChange}
                                    className={`border rounded-md p-1 bg-black ${formErrors.videoUrl ? 'border-red-500' : 'border-white'}`}
                                />
                                {formErrors.videoUrl && <span className="text-red-500 text-sm">{formErrors.videoUrl}</span>}
                            </label>

                            <label htmlFor="channelName" className="flex flex-col items-start gap-2">
                                Channel Name
                                {channelNames && channelNames.length > 0 ? (
                                    <Selection
                                        key={`channelName-${selectionKey}`} // Add key for reset
                                        options={channelNames}
                                        onSelect={handleChannelSelect}
                                        className={formErrors.channelName ? 'border-red-500' : ''}
                                    />
                                ) : (
                                    <p>No Channel</p>
                                )}
                                {formErrors.channelName && <span className="text-red-500 text-sm">{formErrors.channelName}</span>}
                            </label>

                            <label htmlFor="priority" className="flex flex-col items-start gap-2">
                                Priority
                                <Selection
                                    key={`priority-${selectionKey}`} // Add key for reset
                                    options={['Low', 'High']}
                                    onSelect={handleSelectChange}
                                    className={formErrors.priority ? 'border-red-500' : ''}
                                />
                                {formErrors.priority && <span className="text-red-500 text-sm">{formErrors.priority}</span>}
                            </label>
                        </div>

                        <div className="flex justify-center items-center w-full">
                            <button
                                className="w-2/4 p-2 mt-2 rounded-lg bg-gradient-to-r from-sky-400 to-sky-800 text-white"
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                {loading ? 'Loading...' : 'Submit'}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </main>
    );
}
