import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { verifyArtistChannel } from '../store/slice/MusicDisSlice'; 
import CustomAlert from '../components/ui/CustomAlert'; 


const MusicDistributionArtist = () => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        email: '',
        artistName: '',
        oAndOChannel: '',
        artistTopicChannel: '',
        officialMusicVideoId: '',
        labelName: '',
    });

    const [alert, setAlert] = useState({ show: false, icon: '', title: '', message: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(verifyArtistChannel(formData)).unwrap();
            setAlert({
                show: true,
                icon: 'success',
                title: 'Thank You',
                message: 'OAC Verification Applied Successfully!',
            });

            setFormData({
                email: '',
                artistName: '',
                oAndOChannel: '',
                artistTopicChannel: '',
                officialMusicVideoId: '',
                labelName: '',
            });
        } catch (error) {
            setAlert({
                show: true,
                icon: 'error',
                title: 'Error',
                message: 'Error verifying artist channel. Please try again.',
            });
        }
    };

    const closeAlert = () => {
        setAlert({ ...alert, show: false });
    };

    return (
        <>
            <div className="musicd text-white  flex justify-center items-center">
                <div className="w-[100%] lg:w-[32rem] md:w-[60%] flex flex-col gap-4 px-8">
                    <div className="mt-20">
                        <h1 className="text-xl font-bold mb-2">OAC verification</h1>
                        <p>Verify your artist channel for access the page</p>
                    </div>
                    <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="email">
                                Email <span className="text-blue-500 text-md">*</span>{' '}
                                <span className="text-gray-500"> (Mandatory)</span>
                            </label>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="bg-transparent border border-gray-500 rounded-sm py-2 rounded-md"
                                required
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="artistName">
                                Artist Name <span className="text-blue-500 text-md">*</span>{' '}
                                <span className="text-gray-500"> (Mandatory)</span>
                            </label>
                            <input
                                type="text"
                                id="artistName"
                                name="artistName"
                                value={formData.artistName}
                                onChange={handleChange}
                                className="bg-transparent border border-gray-500 rounded-sm py-2 rounded-md"
                                required
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="oAndOChannel">
                                O&O Channel <span className="text-blue-500 text-md">*</span>{' '}
                                <span className="text-gray-500"> (Mandatory)</span>
                            </label>
                            <input
                                type="text"
                                id="oAndOChannel"
                                name="oAndOChannel"
                                value={formData.oAndOChannel}
                                onChange={handleChange}
                                className="bg-transparent border border-gray-500 rounded-sm py-2 rounded-md"
                                required
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="artistTopicChannel">
                                Artist Topic Channel <span className="text-blue-500 text-md">*</span>{' '}
                                <span className="text-gray-500"> (Mandatory)</span>
                            </label>
                            <input
                                type="text"
                                id="artistTopicChannel"
                                name="artistTopicChannel"
                                value={formData.artistTopicChannel}
                                onChange={handleChange}
                                className="bg-transparent border border-gray-500 rounded-sm py-2 rounded-md"
                                required
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="officialMusicVideoId">
                                Official Music Video ID <span className="text-blue-500 text-md">*</span>{' '}
                                <span className="text-gray-500"> (Mandatory)</span>
                            </label>
                            <input
                                type="text"
                                id="officialMusicVideoId"
                                name="officialMusicVideoId"
                                value={formData.officialMusicVideoId}
                                onChange={handleChange}
                                className="bg-transparent border border-gray-500 rounded-sm py-2 rounded-md"
                                required
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="labelName">
                                Label Name<span className="text-blue-500 text-md">*</span>{' '}
                                <span className="text-gray-500"> (Mandatory)</span>
                            </label>
                            <input
                                type="text"
                                id="labelName"
                                name="labelName"
                                value={formData.labelName}
                                onChange={handleChange}
                                className="bg-transparent border border-gray-500 rounded-sm py-2 rounded-md"
                                required
                            />
                        </div>

                        <button type="submit" className="bg-gradient-to-r from-sky-400 to-sky-800 p-2 rounded-md w-[50%] mb-20 text-white hover:no-underline">
                            VERIFY CHANNEL
                        </button>
                    </form>
                </div>
            </div>
            {alert.show && (
                <CustomAlert
                    show={alert.show}
                    icon={alert.icon}
                    title={alert.title}
                    message={alert.message}
                    onClose={closeAlert}
                />
            )}
        </>
    );
};

export default MusicDistributionArtist;
