import React, { Fragment, useEffect, useState } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { GoBell } from 'react-icons/go'
import userAvatar from '../../images/profile.png'
import { fetchNotifications } from '../../store/slice/NotifiSlice'

export default function Header() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isSearchOpen, setIsSearchOpen] = useState(false)
    const [profileImage, setProfileImage] = useState(userAvatar)
    const notifications = useSelector((state) => state.notifications.notifications)
    const notificationStatus = useSelector((state) => state.notifications.status)

    useEffect(() => {
        const storedProfileImage = localStorage.getItem('profile_img')
        if (storedProfileImage && storedProfileImage !== 'undefined') {
            setProfileImage(storedProfileImage)
        }
    }, [])

    useEffect(() => {
        if (notificationStatus === 'idle') {
            dispatch(fetchNotifications())
        }
    }, [notificationStatus, dispatch])

    return (
        <div className="  h-12 flex items-center border-b border-gray-200 justify-between w-auto relative pr-12">
            <div></div>
            <div className="flex items-center gap-2 mr-2">
                <Popover className="relative">
                    {({ open }) => (
                        <>
                            <Popover.Button
                                className={classNames(
                                    open && 'bg-gray-200',
                                    ' group inline-flex items-center rounded-full p-2 text-gray-700 hover:text-opacity-100 focus:outline-none bg-black shadow-lg'
                                )}
                            >
                                <GoBell fontSize={24} color="white" className="shadow-lg animate-pulse " />
                            </Popover.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel className="absolute right-0 z-20 mt-2.5 transform w-[50vw] lg:w-[30vw]">
                                    <div className="bg-indigo-400 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 px-4 py-4">
                                        <strong className="text-white text-xl font-medium ">Notifications</strong>
                                        <div className="bg-fixed mt-2 py-2 text-sm max-h-[70vh] overflow-y-auto">
                                            {notifications.length > 0 ? (
                                                <ul>
                                                    {notifications.slice(0, 20).map((notification, index) => (
                                                        <li
                                                            key={index}
                                                            className="border-b border-gray-200 last:border-0 py-2"
                                                        >
                                                            <p className="font-semibold bg-yellow-300 text-md">
                                                                {notification.title}
                                                            </p>
                                                            <p className="text-white text-lg">{notification.message}</p>
                                                            <p className="text-slate-200 text-xs mt-1">
                                                                {new Date(
                                                                    notification.notificationCreatedAt
                                                                ).toLocaleString()}
                                                            </p>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p className="text-gray-700">No notifications available.</p>
                                            )}
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>
                <Menu as="div" className="relative">
                    <div>
                        <Menu.Button className="ml-2 bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
                            <span className="sr-only">Open user menu</span>
                            <div
                                className="h-10 w-10 rounded-full bg-sky-500 bg-cover border-solid border-2 border-white bg-no-repeat bg-center"
                                style={{ backgroundImage: `url(${profileImage})` }}
                            >
                                <span className="sr-only">User Avatar</span>
                            </div>
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                                {({ active }) => (
                                    <div
                                        onClick={() => navigate('/app/profile')}
                                        className={classNames(
                                            active && 'bg-gray-100',
                                            'active:bg-gray-200 rounded-md px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
                                        )}
                                    >
                                        Your Profile
                                    </div>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </div>
    )
}
