import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'
import Header from './Header'
import backgroundImage from '../../images/Backround.jpg'

export default function Layout() {
	return (
		<div className="bg-neutral-300 h-screen w-screen overflow-hidden flex flex-row">
			<Sidebar />
			<div className="flex flex-col flex-1 bg-center bg-cover "
			  style={{ backgroundImage: `url(${backgroundImage})`,}}
			>
				<Header />
				<div className="flex-1 p-2  min-h-0 overflow-auto">
					<Outlet />
				</div>
			</div>
		</div>
	)
}
