import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

// Fetch client data
export const fetchClientData = createAsyncThunk('client/fetchClientData', async (_, { dispatch }) => {
    try {
        const clientId = localStorage.getItem('clientID') 
        const currentDate = new Date().toISOString().split('T')[0]
        const fullName = localStorage.getItem('fullName')

        dispatch(setClientId(clientId))
        dispatch(setDate(currentDate))
        dispatch(setFullName(fullName))

        return { clientId, currentDate, fullName }
    } catch (error) {
        throw new Error('Failed to fetch client data')
    }
})

// Create claim request
export const createClaimRequest = createAsyncThunk(
    'client/createClaimRequest',
    async (payload, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) {
                throw new Error('No token found. Please login again.')
            }

            const response = await axios.post(`${process.env.REACT_APP_API_KEY}/v1/api/CreateClaimRequest`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })

            if (response.status === 201) {
                return response.data
            } else {
                return rejectWithValue('Request failed')
            }
        } catch (error) {
            return rejectWithValue(error.message || 'Network error')
        }
    }
)

// Verify artist channel
export const verifyArtistChannel = createAsyncThunk(
    'client/verifyArtistChannel',
    async (payload, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) {
                throw new Error('No token found. Please login again.')
            }

            const response = await axios.post(
                `${process.env.REACT_APP_API_KEY}/v1/api/ArtistChannelVerification/create`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            )

            if (response.status === 201) {
                return response.data
            } else {
                return rejectWithValue('Request failed')
            }
        } catch (error) {
            return rejectWithValue(error.message || 'Network error')
        }
    }
)

// Fetch claim data
export const fetchClaimData = createAsyncThunk(
    'client/fetchClaimData',
    async (_, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('token')
            if (!token) {
                throw new Error('No token found. Please login again.')
            }

            const response = await axios.get(`${process.env.REACT_APP_API_KEY}/v1/api/getAllClaimRequestByuser`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            if (response.status === 200) {
                return response.data
            } else {
                return rejectWithValue('Request failed')
            }
        } catch (error) {
            return rejectWithValue(error.message || 'Network error')
        }
    }
)

const initialState = {
    clientId: '',
    date: '',
    fullName: '',
    loading: false,
    error: null,
    claimData: [] 
}

const MusicDisSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setClientId: (state, action) => {
            state.clientId = action.payload
        },
        setDate: (state, action) => {
            state.date = action.payload
        },
        setFullName: (state, action) => {
            state.fullName = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchClientData.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchClientData.fulfilled, (state, action) => {
                state.loading = false
                state.clientId = action.payload.clientId
                state.date = action.payload.currentDate
                state.fullName = action.payload.fullName
            })
            .addCase(fetchClientData.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
            .addCase(createClaimRequest.pending, (state) => {
                state.loading = true
            })
            .addCase(createClaimRequest.fulfilled, (state) => {
                state.loading = false
                state.error = null
            })
            .addCase(createClaimRequest.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(verifyArtistChannel.pending, (state) => {
                state.loading = true
            })
            .addCase(verifyArtistChannel.fulfilled, (state) => {
                state.loading = false
                state.error = null
            })
            .addCase(verifyArtistChannel.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(fetchClaimData.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchClaimData.fulfilled, (state, action) => {
                state.loading = false
                state.claimData = action.payload.data
                state.error = null
            })
            .addCase(fetchClaimData.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    }
})

export const { setClientId, setDate, setFullName } = MusicDisSlice.actions
export default MusicDisSlice.reducer
