import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setFormData } from '../../store/slice/AuthSlice';
import toast from 'react-hot-toast';
import Preloader from '../../components/ui/Preloader'; 

const Register = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true); 
    const [errors, setErrors] = useState({});

    const [form, setForm] = useState({
        fullName: '',
        email: '',
        mobileNumber: '',
        describesYou: ''
    });

    useEffect(() => {
        
        setTimeout(() => setLoading(false), 4000); 
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));
    };

    const handleRadioChange = (e) => {
        setForm((prevForm) => ({
            ...prevForm,
            describesYou: e.target.value
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!form.fullName) newErrors.fullName = 'Full name is required.';
        if (!form.email) newErrors.email = 'Email is required.';
        if (!form.mobileNumber) newErrors.mobileNumber = 'Phone number is required.';
        if (!form.describesYou) newErrors.describesYou = 'Please select an option that best describes you.';

        return newErrors;
    };

    const handleNextClick = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            toast.error('Please fill out all fields.');
            return;
        }

        dispatch(setFormData(form));
        navigate('/region', { state: { formData: form } });
    };

    if (loading) {
        return <Preloader />;
    }

    return (
        <div className="w-full py-10 h-full flex flex-col items-center justify-center bg-black">
            <form className="bg-white shadow-md flex gap-4 rounded  w-[90vw] lg:w-[70vw]">
                <div className="w-full md:lg-1/2 lg:w-1/2 flex flex-col px-8 pt-6 pb-8 mb-4">
                    <h2 className="font-poppins text-20px font-bold leading-24px tracking-0.012em text-left mb-6">
                        Are you ready to take your music career to the next level?
                    </h2>
                    <p className="mb-6">
                        Fill out this form to get started! We’ll send you documentation on what to expect when you sign
                        up with BrandRoute Media and information on how to create your own BrandRoute Media Account so
                        that you can begin submitting your music and videos directly to BrandRoute Media!
                    </p>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fullName">
                            Full Name <span className="text-rose-600 font-bold">*</span>
                        </label>
                        <input
                            type="text"
                            name="fullName"
                            id="fullName"
                            value={form.fullName}
                            onChange={handleChange}
                            className="appearance-none border rounded w-full py-3 px-3 text-gray-900 leading-tight focus:outline-none"
                            placeholder="Enter your full name"
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                            Email <span className="text-rose-600 font-bold">*</span>
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={form.email}
                            onChange={handleChange}
                            className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none"
                            placeholder="Enter your email"
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="mobileNumber">
                            Phone Number <span className="text-rose-600 font-bold">*</span>
                        </label>
                        <input
                            type="text"
                            name="mobileNumber"
                            id="mobileNumber"
                            value={form.mobileNumber}
                            onChange={handleChange}
                            className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none"
                            placeholder="Enter your phone number"
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Which of these best describes you? <span className="text-rose-600 font-bold">*</span>
                        </label>
                        <div className="grid grid-cols-2 grid-rows-3 border border-black p-2 rounded-md">
                            {['Artist', 'Producer', 'Manager', 'Record Label', 'Other'].map((option) => (
                                <div className="flex items-center" key={option}>
                                    <input
                                        type="radio"
                                        name="describesYou"
                                        value={option}
                                        checked={form.describesYou === option}
                                        onChange={handleRadioChange}
                                        className="mr-2"
                                    />
                                    <label className="text-gray-700">{option}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="mb-40">
                        <button
                            className="bg-black w-full text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleNextClick}
                        >
                            Next
                        </button>
                        <div className="flex justify-between items-center mt-2 text-sm">
                            <p>Are you already registered user?</p>
                            <p>
                                <Link to="/login">Login</Link>
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className="w-0 md:w-1/2 lg:w-1/2"
                    style={{
                        backgroundImage: 'url(assets/bgform.png)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                ></div>
            </form>
        </div>
    );
};

export default Register;
