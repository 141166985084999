import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import ReleaseClaim from '../components/ReleaseClaim';
import { fetchClaimReleaseData } from '../store/slice/ReleaseSlice'; 
import Loader from '../components/shared/Loader';
const backgroundImage = require('../images/Backround.jpg')

const ClaimRelease = () => {
    const dispatch = useDispatch();
    const { claimReleaseData, loading } = useSelector((state) => state.release);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        dispatch(fetchClaimReleaseData());
    }, [dispatch]);

    if (loading === true) {
        return <Loader />
    }

    if (!claimReleaseData) {
        return <div>No data available</div>
    }

    const handleNextPage = () => {
        if (currentPage * itemsPerPage < claimReleaseData.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleClaimRequest = () => {
        dispatch(fetchClaimReleaseData());
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentData = claimReleaseData.slice(startIndex, startIndex + itemsPerPage);

    const startRow = (currentPage - 1) * itemsPerPage + 1;
    const endRow = Math.min(currentPage * itemsPerPage, claimReleaseData.length);

    return (
        <div>
            <div className="flex h-auto flex-wrap justify-between bg-cover bg-center shadow-lg rounded-lg"
              style={{ backgroundImage: `url(${backgroundImage})`}}
            >
                <div className="flex flex-col px-3 py-3">
                    <h1 className="poppins text-2xl font-semibold">Claim Release Request</h1>
                    <p className="text-[16px] font-normal">Keep Track of video Progress and record...</p>
                </div>
                <div className='p-3'>
                    <ReleaseClaim onClaimReleased={handleClaimRequest} />
                </div>

                <div className="mt-2 w-full p-4 ">
                    <Table>
                        <Thead className='text-white bg-blue-600'>
                            <Tr>
                                <Th>Creator Name</Th>
                                <Th>Client ID</Th>
                                <Th>Date</Th>
                                <Th>Content ID</Th>
                                <Th>Video URL</Th>
                                <Th className='text-center'>Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentData.map((claimRelease) => (
                                <Tr key={claimRelease._id}>
                                    <Td>{claimRelease.creatorName}</Td>
                                    <Td>{claimRelease.clientId}</Td>
                                    <Td>{new Date(claimRelease.date).toLocaleDateString()}</Td>
                                    <Td>{claimRelease.contentIdBy}</Td>
                                    <Td>
                                        <a href={claimRelease.videoUrl} target="_blank" rel="noopener noreferrer">
                                            Video URL
                                        </a>
                                    </Td>
                                    <Td className='flex items-center justify-center'>
                                        <button
                                            className={`h-8 font-semibold w-24 rounded ${
                                                claimRelease.status === 'Rejected' ? 'bg-red-500' : 'bg-blue-500'
                                            } text-white p-1`}
                                        >
                                            {claimRelease.status}
                                        </button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    <div className="mt-2">
                        <p className="mt-6  text-red-600">
                            Showing {startRow}-{endRow} of {claimReleaseData.length} rows selected
                        </p>
                    </div>
                </div>
                <div className="flex justify-between w-full p-4">
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="bg-blue-500 text-white p-2 rounded disabled:bg-gray-400"
                    >
                        Previous
                    </button>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage * itemsPerPage >= claimReleaseData.length}
                        className="bg-blue-500 text-white p-2 rounded disabled:bg-gray-400"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ClaimRelease;
