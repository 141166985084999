import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { sendOTP, verifyOTP } from '../store/slice/ForgotSlice';
import CustomAlert from '../components/ui/CustomAlert'; 

const OTPSend = () => {
    const dispatch = useDispatch();
    const { loading, error, otpSent, verified } = useSelector((state) => state.forgot);
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [alert, setAlert] = useState({ show: false, icon: '', title: '', message: '' });

    const handleEmailSubmit = () => {
        dispatch(sendOTP(email)).then(() => {
            setShowVerifyModal(true);
            setOpen(false); 
            setAlert({
                show: true,
                icon: 'success',
                title: 'Thank You',
                message: 'An OTP has been sent to your email.'
            });
        }).catch((err) => {
            console.error(err);
            setAlert({
                show: true,
                icon: 'error',
                title: 'Error',
                message: 'Failed to send OTP. Please try again.'
            });
        });
    };

    const handleOTPSubmit = () => {
        dispatch(verifyOTP({ email, otp, newPassword })).then(() => {
            setShowVerifyModal(false);
            setAlert({
                show: true,
                icon: 'success',
                title: 'Thank You',
                message: 'Your password has been reset successfully.'
            });
        }).catch((err) => {
            console.error(err);
            setAlert({
                show: true,
                icon: 'error',
                title: 'Error',
                message: 'Failed to reset password. Please try again.'
            });
        });
    };

    const closeAlert = () => {
        setAlert({ ...alert, show: false });
    };

    return (
        <div>
            <p onClick={() => setOpen(true)} className="cursor-pointer text-blue-500 underline">
                Forgot Password
            </p>
            {open && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div className="relative bg-white p-6 rounded-lg shadow-lg z-10">
                        <button
                            onClick={() => setOpen(false)}
                            className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                        >
                            <AiOutlineClose className="h-6 w-6" />
                        </button>
                        <h2 className="text-xl font-semibold mb-4">Send OTP</h2>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            className="w-full border border-gray-300 p-2 rounded-md mb-4"
                        />
                        <button
                            onClick={handleEmailSubmit}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md w-full"
                            disabled={loading}
                        >
                            {loading ? 'Sending...' : 'Send OTP'}
                        </button>
                        {error && <p className="text-red-500 mt-2">{error}</p>}
                    </div>
                </div>
            )}
            {showVerifyModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div className="relative bg-white w-[30vw] p-6 rounded-lg shadow-lg z-10">
                        <button
                            onClick={() => setShowVerifyModal(false)}
                            className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                        >
                            <AiOutlineClose className="h-6 w-6" />
                        </button>
                        <h2 className="text-xl font-semibold mb-4">Verify OTP</h2>
                        <div className='flex flex-col items-center justify-center'>
                            <input
                                type="text"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder="Enter OTP"
                                className="w-full border border-gray-300 p-2 rounded-md mb-4"
                            />
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="Enter new password"
                                className="w-full border border-gray-300 p-2 rounded-md mb-4"
                            />
                        </div>
                        <button
                            onClick={handleOTPSubmit}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md w-full"
                            disabled={loading}
                        >
                            {loading ? 'Verifying...' : 'Reset Password'}
                        </button>
                        {error && <p className="text-red-500 mt-2">{error}</p>}
                    </div>
                </div>
            )}
            <CustomAlert
                show={alert.show}
                icon={alert.icon}
                title={alert.title}
                message={alert.message}
                onClose={closeAlert}
            />
        </div>
    );
};

export default OTPSend;
