import React, { useState } from 'react'
import classNames from 'classnames'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Menu, X } from 'react-feather'
import Dropdown from '../ui/Dropdown'
import { useAuth } from '../../context/AuthContext'
import logo from '../../images/CRM LOGO.png'
import sidebarBg from '../../images/finalbg.png'
import Dash from '../../images/1.png'
import MusicIcon from '../../images/4.png'
import VisualClaimIcon from '../../images/2.png'
import MCNConnectIcon from '../../images/connect.png'
import ReportsIcon from '../../images/5.png'
import termsIcon from '../../images/7.png'
import power from '../../images/POWER LOGOUT.png'

const linkClass =
    'flex rounded-lg bg-zinc-700 opacity-75 items-center gap-2 px-1 py-1 hover:bg-blue-300 hover:no-underline active:bg-blue-500 text-white'
const collapsedLinkClass =
    'flex items-center gap-2 font-medium px-3 py-2 hover:bg-blue-300 hover:no-underline active:bg-blue-500 rounded-sm text-xl text-white/0'
const typographyClass = 'font-poppins text-[10px] lg:text-sm leading-8 tracking-wide text-left'

export default function Sidebar() {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const { logout } = useAuth()

    const handleLogout = () => {
        localStorage.removeItem('token')
        logout()
        navigate('/login')
    }

    const isActiveLink = (link) => location.pathname === link

    return (
        <div
            className={classNames('p-3 mt-1  flex flex-col relative h-full', {
                'w-62': !isCollapsed,
                'w-16': isCollapsed
            })}
        >
            <div
                className="absolute  mb-2 rounded-e-[50px]  inset-0 bg-cover bg-center"
                style={{ backgroundImage: `url(${sidebarBg})` }}
            ></div>
            <div className="relative z-10 flex flex-col justify-between h-full">
                {/* Top Section */}
                <div className="flex flex-col">
                    <div className="flex items-center flex-col pt-4 gap-2 flex-wrap px-1 py-3">
                        <button className="pt-0" onClick={() => setIsCollapsed(!isCollapsed)}>
                            {isCollapsed ? (
                                <Menu className="w-6 h-6 text-neutral-100" />
                            ) : (
                                <X className="w-6 h-6 text-neutral-100" />
                            )}
                        </button>
                        {!isCollapsed && <img src={logo} className="w-24 h-24" alt="logo" />}
                    </div>

                    <div
                        className={classNames('py-8 flex flex-1 flex-col gap-1', {
                            'overflow-hidden': isCollapsed,
                            'h-48': isCollapsed
                        })}
                    >
                        <Link
                            to="/app"
                            className={classNames(
                                linkClass,
                                {
                                    'bg-blue-500': isActiveLink('/app')
                                },
                                typographyClass
                            )}
                        >
                            <img src={Dash} className="w-6 h-6" alt="Dashboard" />
                            {!isCollapsed && (
        <span style={{ letterSpacing: '0.2em' }}>DASHBOARD</span>
    )}
                        </Link>

                        <Dropdown
                            imageSrc={MusicIcon}
                            label="Music Distribution"
                            links={[
                                { label: 'Distribution Connect', href: '/app/distributionconnect' },
                                { label: 'Claim Release Request', href: '/app/music' },
                                { label: 'OAC verification', href: '/app/musicDistributionArtist' }
                            ]}
                            isCollapsed={isCollapsed}
                            isActiveParent={isActiveLink('/dashboard/music')}
                        />

                        <Dropdown
                            imageSrc={VisualClaimIcon}
                            label="Visual Claim"
                            links={[
                                { label: 'Start Claiming', href: '/app/claims' },
                                { label: 'Claim Release Request', href: '/app/claimRelease' },
                                { label: 'Whitelist Request', href: '/app/whitelist' }
                            ]}
                            isCollapsed={isCollapsed}
                        />

                        <Dropdown
                            imageSrc={MCNConnectIcon}
                            label="MCN Connect"
                            links={[
                                { label: 'Channel Linking Request', href: '/app/connects' },
                                { label: 'MCN Channel Records', href: '/app/list' }
                            ]}
                            isCollapsed={isCollapsed}
                        />

                        <Link
                            to="/app/reports"
                            className={classNames(
                                linkClass,
                                {
                                    'bg-blue-500': isActiveLink('/dashboard')
                                },
                                typographyClass
                            )}
                        >
                            <img src={ReportsIcon} className="w-6 h-6" alt="Dashboard" />
                            {!isCollapsed && 'Reports & Payment'}
                        </Link>
                    </div>
                </div>

                {/* Bottom Section */}
                <div className="flex flex-col justify-center items-center gap-0.5 pt-2">
                    <Link
                        to="/app/terms"
                        className={classNames(
                            linkClass,
                            {
                                'bg-blue-500': isActiveLink('/dashboard/terms')
                            },
                            typographyClass
                        )}
                    >
                        <img src={termsIcon} className="w-6 h-6" alt="Terms of Service" />
                        {!isCollapsed && <span>Terms of Service</span>}
                    </Link>
                    <button className="flex flex-col items-center justify-center mb-4" onClick={handleLogout}>
                        <img src={power} className="w-6 h-6" alt="Logout" />
                        {!isCollapsed && <p className="text-white text-[10px]">LOGOUT</p>}
                    </button>
                </div>
            </div>
        </div>
    )
}
