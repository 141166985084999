import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Layout from './components/shared/Layout'

import Dashboard from './pages/Dashboard'

import MusicDistribuotion from './pages/MusicDistribuotion'
import Claims from './pages/Claims'
import Connects from './pages/Connects'
import ReportsPayments from './pages/ReportsPayments'
import Termservice from './pages/Termservice'
import HelpSupport from './pages/HelpSupport'

import MusicDistributionArtist from './pages/MusicDistributionArtist'
import ConnectList from './pages/ConnectList'
import DistributionConnect from './pages/DistributionConnect'

import RegionSelect from './pages/Authentication/RegionSelect'
import TermAccept from './pages/Authentication/TermAccept'
import Register from './pages/Authentication/Register'
import Login from './pages/Login'
import { Toaster } from 'react-hot-toast'
import ClaimRelease from './pages/ClaimRelease'
import Whitelist from './pages/Whitelist'
import Profie from './pages/Profie'
import ProtectRoute from './private/ProtectRoute'
import { AuthProvider } from './context/AuthContext'

function App() {
    return (
        <AuthProvider>
            <Router>
                <Toaster />
                <Routes>
                    <Route path="/" element={<Register />} />
                    <Route
                        path="/app/*"
                        element={
                            <ProtectRoute>
                                <Layout />
                            </ProtectRoute>
                        }
                    >
                        <Route index element={<Dashboard />} />
                        <Route path="music" element={<MusicDistribuotion />} />
                        <Route path="profile" element={<Profie />} />
                        <Route path="musicDistributionArtist" element={<MusicDistributionArtist />} />
                        <Route path="claims" element={<Claims />} />
                        <Route path="connects" element={<Connects />} />
                        <Route path="reports" element={<ReportsPayments />} />
                        <Route path="terms" element={<Termservice />} />
                        <Route path="helps" element={<HelpSupport />} />
                        <Route path="list" element={<ConnectList />} />
                        <Route path="distributionconnect" element={<DistributionConnect />} />
                        <Route path="claimRelease" element={<ClaimRelease />} />
                        <Route path="whitelist" element={<Whitelist />} />
                    </Route>
                    <Route path="region" element={<RegionSelect />}></Route>
                    <Route path="term" element={<TermAccept />}></Route>
                    <Route path="login" element={<Login />}></Route>
                </Routes>
            </Router>
        </AuthProvider>
    )
}

export default App
