import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { createMCN } from '../store/slice/mcnSlice';
import CustomAlert from '../components/ui/CustomAlert';

const Connects = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        channelUrl: '',
        channelType: '',
        description: '',
        googleDriveLink: '',
        screenshot: null,
        channelCopyright: null
    });

    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState({
        icon: 'success',
        title: '',
        message: ''
    });

    const [formErrors, setFormErrors] = useState({});
    const screenshotInputRef = useRef(null);
    const channelCopyrightInputRef = useRef(null);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormErrors({ ...formErrors, [name]: false });  // Clear error on field change
        if (files) {
            setFormData({ ...formData, [name]: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.channelUrl) errors.channelUrl = 'Channel URL is required';
        if (!formData.channelType) errors.channelType = 'Channel type is required';
        if (!formData.description) errors.description = 'Description is required';
        if (!formData.googleDriveLink) errors.googleDriveLink = 'Google Drive link is required';
        if (!formData.screenshot) errors.screenshot = 'Screenshot is required';
        if (!formData.channelCopyright) errors.channelCopyright = 'Channel copyright document is required';

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            setAlertData({
                icon: 'error',
                title: 'Form Validation Error',
                message: 'Please fill out all the required fields.'
            });
            setShowAlert(true);
            return;
        }

        const apiFormData = new FormData();
        for (let key in formData) {
            if (formData[key] !== null) {
                apiFormData.append(key, formData[key]);
            }
        }

        dispatch(createMCN(apiFormData))
            .then((response) => {
                if (response.payload.status === 'success') {
                    setAlertData({
                        icon: 'success',
                        title: 'Thank You!',
                        message: 'Your multi-channel network has been created successfully.'
                    });
                    clearForm();
                } else {
                    setAlertData({
                        icon: 'error',
                        title: 'Error',
                        message: 'Failed to create multi-channel network. Please try again.'
                    });
                }
                setShowAlert(true);
            })
            .catch((error) => {
                console.error('Failed to create MCN:', error);
                setAlertData({
                    icon: 'error',
                    title: 'Error',
                    message: 'Failed to create multi-channel network. Please try again.'
                });
                setShowAlert(true);
            });
    };

    const clearForm = () => {
        setFormData({
            channelUrl: '',
            channelType: '',
            description: '',
            googleDriveLink: '',
            screenshot: null,
            channelCopyright: null
        });

        if (screenshotInputRef.current) {
            screenshotInputRef.current.value = null;
        }

        if (channelCopyrightInputRef.current) {
            channelCopyrightInputRef.current.value = null;
        }

        setFormErrors({});
    };

    return (
        <div className="connect">
            <CustomAlert
                show={showAlert}
                icon={alertData.icon}
                title={alertData.title}
                message={alertData.message}
                onClose={() => setShowAlert(false)}
            />
            <div className="flex flex-col p-10 ">
                <div className="flex flex-col text-center pb-4 text-white">
                    <h1 className="text-2xl font-semibold">Multi-channel Network</h1>
                    <p className="mt-1">Request for linking and creating channel</p>
                    <p className="text-base font-semibold mt-2">Please Fill Up Following Channel Information</p>
                </div>

                {/* INPUT FIELDS */}
                <div className="flex flex-col-reverse lg:flex-row justify-evenly text-white">
                    <div className="w-[100%] lg:w-[60%] p-8 ">
                        <form onSubmit={handleSubmit}>
                            <div className="flex gap-4">
                                <label className="flex flex-col">
                                    <p>
                                        URL of the channel <span className="text-rose-600 font-bold">*</span>
                                    </p>
                                    <input
                                        type="text"
                                        name="channelUrl"
                                        value={formData.channelUrl}
                                        onChange={handleChange}
                                        className={`mt-1 py-2 bg-transparent border ${formErrors.channelUrl ? 'border-red-600' : 'border-gray'} px-4 rounded`}
                                    />
                                    {formErrors.channelUrl && <p className="text-red-500 text-sm">{formErrors.channelUrl}</p>}
                                </label>
                                <label className="flex flex-col">
                                    <p>
                                        Channel Type <span className="text-rose-600 font-bold">*</span>
                                    </p>
                                    <select
                                        name="channelType"
                                        value={formData.channelType}
                                        onChange={handleChange}
                                        className={`bg-gray-900 border ${formErrors.channelType ? 'border-red-600' : 'border-white'} p-2 text-white mt-1`}
                                    >
                                        <option value="">Select</option>
                                        <option value="entertainment">Entertainment</option>
                                        <option value="music">Music</option>
                                    </select>
                                    {formErrors.channelType && <p className="text-red-500 text-sm">{formErrors.channelType}</p>}
                                </label>
                            </div>

                            <label className="flex mt-2 flex-col">
                                <p>
                                    Brief Description of its Content (500 words)
                                    <span className="text-rose-600 font-bold">*</span>
                                </p>
                                <textarea
                                    rows={8}
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    className={`mt-1 border ${formErrors.description ? 'border-red-600' : 'border-gray'}-200 rounded bg-transparent p-2`}
                                />
                                {formErrors.description && <p className="text-red-500 text-sm">{formErrors.description}</p>}
                            </label>

                            <label className="flex flex-col mt-4">
                                <p>
                                    Pdf of the channel copyrights (.pdf)* (max 500KB)
                                    <span className="text-rose-600 font-bold">*</span>
                                </p>
                                <div className={`flex border mt-1 justify-between w-full rounded-md ${formErrors.screenshot ? 'border-red-600' : 'border-gray'}`}>
                                    <input
                                        type="file"
                                        name="screenshot"
                                        accept=".pdf,.jpg,.jpeg,.png"
                                        onChange={handleChange}
                                        className="hidden"
                                        ref={screenshotInputRef}
                                    />
                                    <p className="px-4 py-2 text-gray-300">
                                        {formData.screenshot ? formData.screenshot.name : 'Choose from your device'}
                                    </p>
                                    <img src="./assets/Group 295.png" alt="" className="cursor-pointer" onClick={() => screenshotInputRef.current.click()} />
                                </div>
                                {formErrors.screenshot && <p className="text-red-500 text-sm">{formErrors.screenshot}</p>}
                            </label>

                            <label className="flex flex-col mt-4">
                                <p>
                                    Pdf of the below mentioned (.pdf)* (max 500KB)
                                    <span className="text-rose-600 font-bold">*</span>
                                </p>
                                <div className={`flex border mt-1 justify-between w-full rounded-md ${formErrors.channelCopyright ? 'border-red-600' : 'border-gray'}`}>
                                    <input
                                        type="file"
                                        name="channelCopyright"
                                        accept=".pdf,.jpg,.jpeg,.png"
                                        onChange={handleChange}
                                        className="hidden"
                                        ref={channelCopyrightInputRef}
                                    />
                                    <p className="px-4 py-2 text-gray-300">
                                        {formData.channelCopyright ? formData.channelCopyright.name : 'Choose from your device'}
                                    </p>
                                    <img src="./assets/Group 295.png" alt="" className="cursor-pointer" onClick={() => channelCopyrightInputRef.current.click()} />
                                </div>
                                {formErrors.channelCopyright && <p className="text-red-500 text-sm">{formErrors.channelCopyright}</p>}
                            </label>
                            <div className="mt-2">
                                <ul className="list-disc">
                                    <li>The pdf of the dashboard page of each channels.</li>
                                    <li>The pdf of each channel’s last 90 day’s revenue page.</li>
                                    <li>The pdf of the monetization page of each channel.</li>
                                    <li>
                                        The pdf of the content page with the copyright claims filter applied to
                                        each channel.
                                    </li>
                                </ul>
                            </div>

                            <label className="flex mt-2 flex-col">
                                <p>
                                    Please upload the audio license or agreement to Google Drive and share the link below.
                                    <span className="text-rose-600 font-bold">*</span>
                                </p>
                                <input
                                    type="text"
                                    name="googleDriveLink"
                                    value={formData.googleDriveLink}
                                    onChange={handleChange}
                                    className={`mt-1 border ${formErrors.googleDriveLink ? 'border-red-600' : 'border-gray'}-300 rounded py-2 px-2 bg-transparent`}
                                    placeholder="https://docs.google.com/..."
                                />
                                {formErrors.googleDriveLink && <p className="text-red-500 text-sm">{formErrors.googleDriveLink}</p>}
                            </label>

                            <button
                                type="submit"
                                className="text-white bg-blue-500 w-28 m-4 p-2 font-semibold rounded-md"
                            >
                                Submit
                            </button>
                        </form>
                    </div>

                    {/* Requirements Section */}
                    <div className="w-[100%] lg:w-[40%] p-3 rounded-2xl">
                        <div className="flex flex-col text-white mt-1 text-xs border border-2 border-green-600 p-8 rounded-lg">
                            <span className="text-base font-semibold text-red-600">
                                To join us, your channel or channels must meet the following requirements:
                            </span>
                            <p className="mt-2">1. Be musical channels</p>
                            <p className="mt-2">2. Be part of the YouTube partners program</p>
                            <p className="mt-2">3. Have the exclusive rights to all the musical content</p>
                            <p className="mt-2">4. Linked channels will be locked in for a minimum period of 1 Year.</p>
                            <p className="mt-2">
                                5. Content uploaded can be deleted but the channel will not be deleted.
                            </p>
                            <p className="mt-2">
                                6. All content uploaded has to be original and copyrights have to be held by the owner.
                                In case of copyright strikes or copyright infringement, channel content will be deleted
                                and payments not released until suitable requirements are met.
                            </p>
                            <p className="mt-2">
                                7. Any copyright infringement or strike will be addressed by the Owner/aggregator within
                                48 hours and released within 3 days of issue.
                            </p>
                            <p className="mt-2">
                                8. No created channel will be shared or sublet by any owner/aggregator without prior
                                approval from us.
                            </p>
                            <p className="mt-2">
                                9. All Channel creation will be done only upon fulfillment of legal documentation and
                                will not be entertained otherwise.
                            </p>
                            <p className="mt-2">
                                10. Once Linked, channels will not be unlinked for 12 months. In case of any legal
                                disputes/payment issues, legal jurisdiction will be deployed till such grounds are
                                fulfilled.
                            </p>
                            <p className="mt-2">
                                11. Every strike will be charged $100 and the amount will be deducted from the payment.
                            </p>
                            <p className="mt-2">
                                12. Every copyright Infringement will charge $50 and the amount will be deducted from
                                the payment.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Connects;
