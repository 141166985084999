// src/components/SelectionList.jsx

import React, { useState } from 'react';

const Selection = ({ options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    onSelect(event.target.value);
  };

  return (
    <div className="w-full max-w-xs mx-auto">
     
      <select
        id="select"
        value={selectedOption}
        onChange={handleSelectChange}
        className="border text-white border-white p-1 w-[10rem] bg-black"
      >
        <option value="" disabled>
          Choose an option
        </option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Selection;
